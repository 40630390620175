import React, {FunctionComponent, useEffect} from 'react';
import styles from "./redeemPizza.module.scss";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import RedeemPizzas from "../../../../src/assets/images/redeemPizzas.png";
import {CopyRedeemCode} from "@components/common/svgIcons";
import Icon from '@components/common/icon';
import CopyClipboard from '@components/common/copyClipboard';

interface RedeemPizzaProps {
    code:string|undefined
    onClose:()=>void
}

const RedeemPizza:FunctionComponent<RedeemPizzaProps> = (props) => {

    useEffect(()=>{
    },[])

    return (
        <div className={styles.container}>
            <img src={RedeemPizzas} alt={"Redeem Pizza"}/>
            <div style={{fontSize: '15px;'}}>
                <Text value={"Now you can use the code below and get \n a free pizza."} class={"capitalize"} />
            </div>
            <div className={styles.redeemCode}>
                <CopyClipboard text={props.code || ""} showText={props.code} textStyle={{fontSize: '32px', fontFamily: 'UberMoveBold', color: '#46833A'}}></CopyClipboard>
                <Icon size={24} color={"none"} icon={<CopyRedeemCode/>}/>
            </div>
            <div className={"d-flex gap-2 mt-3"}>
                <CustomButton
                    theme={"primary-outline"}
                    textColor='black'
                    text={<Text value={"close"} class={"capitalize"}/>}
                    onClick={props.onClose}
                />
            </div>
        </div>
    );
};

export default RedeemPizza;
