import React, {FunctionComponent} from 'react';
import styles from "./patternMarker.module.scss";
import ReactDOMServer from "react-dom/server";
import {Pattern} from "@webservices/patterns";
import Divider from "@components/common/divider";
import MergedSlices from "@components/app/mergedSlices";
import SquareMergedSlices from "@components/app/mergedSlices/square";

const PatternMarkerElement:FunctionComponent<Pattern & {patterns:Pattern["slices"][]}> = (props) => {

    const getPatterns = ():((number|undefined)[])[] => {
        let mergedPattern:(number|undefined)[][] = [];
        props.patterns.map((pattern, index) => {
            pattern.map(slice=>{
                if(!mergedPattern[index]) mergedPattern[index] = []
                if(slice.amount === 1){
                    mergedPattern[index].push(slice.type);
                } else {
                    for (let i=0; i<slice.amount; i++){
                        mergedPattern[index].push(slice.type);
                    }
                }
            })
        })
        return mergedPattern;
    }

    return (
        <div className={styles.container}>
            <img src={props.logo} className={styles.logo} alt={props.id}/>
            <p className={"mt-2"}>{props.title}</p>
            <Divider color={"light-gray"}/>
            <div className={"d-flex gap-2"}>
                {
                    getPatterns().map((pattern, index)=>{
                        return <div className={styles.pattern} key={index}>
                            <SquareMergedSlices slices={pattern} borderRadius={3}/>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export const PatternMarker = (Info:Pattern & {patterns:Pattern["slices"][]}):HTMLDivElement => {
    const tag = document.createElement('div');
    tag.innerHTML = ReactDOMServer.renderToString(<PatternMarkerElement{...Info}/>);
    return tag;
}
