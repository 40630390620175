import {collection, getDocs, getDoc, getCountFromServer, doc, query, where, collectionGroup, setDoc, updateDoc, addDoc, limit, increment } from "firebase/firestore";
import {DB} from "../firebaseLoader";
import {CollectionReference, Query, QuerySnapshot} from "@firebase/firestore";

export interface Conditions {
    field: string
    operator: '>='|'<='|'!='|'=='
    value: any
}

const mapSnapShot = <D>(q:QuerySnapshot):D[]=>{
    const data:D[] = [];
    q.forEach((doc) => {
        data.push({id:doc.id, ...doc.data() as D})
    });
    return data;
}

const queryDocs = async <D>(ref:CollectionReference|Query,  conditions?:Conditions[], page?:number, count?:number) => {
    let q = query(ref);
    conditions && conditions.forEach(condition => {
        q = query(q, where(condition.field, condition.operator, condition.value));
    });
    if(count){
        q = query(q, limit(count));
    }
    try {
        const querySnapshot = await getDocs(q);
        return mapSnapShot<D>(querySnapshot);
    } catch (error) {
        console.error('Error fetching documents:', error);
        return [];
    }
}

export const getDocuments = async <D>(docName:string,  conditions?:Conditions[], page?:number, count?:number):Promise<D[]> => {
    const collectionRef = collection(DB(), docName);
    return queryDocs<D>(collectionRef, conditions, page, count);
}

export const getDocumentsCount = async (docName:string,  conditions?:Conditions[]):Promise<number> => {
    const collectionRef = collection(DB(), docName);
    let q = query(collectionRef);
    conditions && conditions.forEach(condition => {
        q = query(q, where(condition.field, condition.operator, condition.value));
    });
    try {
        const snapshot = await getCountFromServer(q);
        return snapshot.data().count;
    } catch (error) {
        console.error('Error fetching documents:', error);
        return 0;
    }
}

export const getSubDocuments = async <D>(docName:string,  conditions?:Conditions[], page?:number, count?:number):Promise<D[]> => {
    const collectionRef = collectionGroup(DB(), docName);
    return queryDocs<D>(collectionRef, conditions, page, count);
}

export const getDocumentById = async <D>(docName:string,  id:string):Promise<D|undefined> => {
    const docRef = doc(DB(), docName, id);
    const documentSnapshot = await getDoc(docRef);
    if(documentSnapshot.data()){
        return {id: documentSnapshot.id, ...documentSnapshot.data()} as D;
    }
}

export const getOneDocument = async <D>(docName:string,  conditions?:Conditions[]):Promise<D|undefined> => {
    const docs = await getDocuments<D>(docName, conditions, undefined, 1);
    if(docs) return docs[0];
}

export const insertDocument = async <D>(docName:string, id:string|undefined, data:{[k:string]:any}):Promise<D|undefined> => {
    try {
        let insertedId:string;
        if(!id){
            const docRef = await addDoc(collection(DB(), docName), data);
            insertedId = docRef.id;
        } else {
            const docRef = doc(DB(), docName, id);
            await setDoc(docRef, data);
            insertedId = docRef.id;
        }
        const insertedRef = doc(DB(), docName, insertedId);
        const insertedDoc = await getDoc(insertedRef);
        return {id: insertedDoc.id, ...insertedDoc.data()} as D;
    } catch (error) {
        console.error('Error inserting documents:', error);
    }
}

export const updateDocument = async <D>(docName:string, id:string, data:{[k:string]:any}):Promise<D|undefined> => {
    try {
        const docRef = doc(DB(), docName, id);
        await updateDoc(docRef, data);
        const updatedDoc = await getDoc(docRef);
        return {id: updatedDoc.id, ...updatedDoc.data()} as D;
    } catch (error) {
        console.error('Error updating documents:', error);
    }
}

