import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";

interface UnblockLocationServiceProps {
    onConfirm: ()=>void
}

const UnblockLocationService:FunctionComponent<UnblockLocationServiceProps> = (props) => {
    return (
        <div>
            <h2>Location access</h2>
            <p className={"my-3"}>In your device settings, follow the steps below to enable location access.</p>
            <p>step 1</p>
            <p>step 2</p>
            <div className={"d-flex justify-content-center gap-3 pt-4"}>
                <CustomButton
                    text={<Text value={"I understand"}/>}
                    onClick={()=>{
                        localStorage.setItem("stopShowUnBlockLocation", "true");
                        props.onConfirm();
                    }}
                    theme={"primary"}
                />
            </div>
        </div>
    );
};

export default UnblockLocationService;