import React, {FunctionComponent, useEffect, useState} from 'react';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {mapLoader} from "@components/common/map/googleMap/provider/mapLoader";

const GoogleMapProvider:FunctionComponent<any> = (props) => {

    const [map, setMap] = useState<google.maps.Map|null>(null);
    const [googleObject, setGoogleObject] = useState<typeof google|null>(null);
    const [zoom, setZoom] = useState<number>();

    useEffect(()=>{
        (async ()=>{
            const google = await mapLoader();
            setGoogleObject(google);
        })()
    }, [])

    return (
        <MapContext.Provider value={{
            map,
            setMap,
            googleObject,
            zoom,
            setZoom
        }}>
            {props.children}
        </MapContext.Provider>
    );
};

export default GoogleMapProvider;