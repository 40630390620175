import React from 'react';
import Header from "@components/layouts/layoutElements/header";


const Support = () => {

    return (
        <>
            <div className={"h-100 d-flex flex-column"}>
                <Header
                    title={"Support"}
                    backButton
                />
                <div
                    className={"d-flex h-100 p-4 overflow-auto"}
                    style={{flexDirection: 'column'}}
                >
                    <p>Support email : omid@omidexinc.com </p>
                </div>
            </div>
        </>
    );
};


export default Support;
