import React, {useState} from 'react';
import styles from "./slices.module.scss";
import Header from "@components/layouts/layoutElements/header";
import Icon from "@components/common/icon";
import {Gift, Heart, Slice as SliceIcon} from "@components/common/svgIcons";
import Modal from "@components/common/modal";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import SlicePreview from "@components/modals/slicePreview";
import {useHistory} from "react-router-dom";
import BottomSheet from "@components/common/bottomSheet";
import SelectSlice from "@components/bottomSheets/selectSlice";
import UserSlices from "@components/app/userSlices";
import ConfirmModal from '@components/modals/confirmModal';
import RedeemPizza from '@components/modals/redeemPizza';
import {getUserSlices, releaseSlices} from '@webservices/slices';
import Confetti from "@assets/images/confetti.svg";
import {setVoucher} from "@webservices/vouchers";
import {Slice} from "@types.d/Slice";
import {failToast} from "@toast/index";
import Alert from "@components/modals/alert";
import {updateFreePizza} from "@webservices/user";

const Slices = () => {

    const history = useHistory();

    const [selectedSlice, setSelectedSlice] = useState<number>();
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);
    const [confirmFreePizza, setConfirmFreePizza] = useState<boolean>(false);
    const [showVoucherCode, setShowVoucherCode] = useState<boolean>(false);
    const [redeemingSlices, setRedeemingSlices] = useState<number[]>([]);
    const [voucherCode, setVoucherCode] = useState<string|undefined>();
    const [freePizzaLoading, setFreePizzaLoading] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>();

    const freePizza = async () => {
        setFreePizzaLoading(true);
        let userSlices = await getUserSlices();
        const slicesToRelease:Slice[] = [];
        redeemingSlices.forEach(typeId => {
            const matchSlice = userSlices.findIndex(s=>s.type === typeId);
            if(matchSlice !== -1) {
                slicesToRelease.push(userSlices[matchSlice]);
                userSlices.splice(matchSlice, 1);
            }
        })
        if(slicesToRelease.length < 8) {
            failToast("You dont have enough slices!!");
            return;
        }
        try{
            const code = await setVoucher();
            updateFreePizza().then(e=>console.log(e))
            await releaseSlices(slicesToRelease);
            setVoucherCode(code);
            setFreePizzaLoading(false);
            setConfirmFreePizza(false);
            setShowVoucherCode(true);
        } catch (e:any) {
            setFreePizzaLoading(false);
            setConfirmFreePizza(false);
            setShowAlert(true);
            setAlertText(e.message);
        }
    }

    return (
        <>
            <Header
                title={"my slices"}
                menu
            />
            <div className={"d-flex flex-column align-items-center px-4 flex-1 overflow-auto pb-5"}>
                <div className={"d-flex flex-column w-100 align-items-center mb-4"}>
                    <UserSlices
                        onSelect={setSelectedSlice}
                        size={"large"}
                        hideLock={true}
                    />
                </div>
                <div
                    className={"d-flex w-100 flex-column align-items-center cms-back-glass p-4"}
                    style={{borderRadius:20}}
                >
                    <p className={"mb-3 text-center"}>Complete our patterns to win exciting prizes!</p>
                    <CustomButton
                        theme={"primary-gradient"}
                        text={<Text value={"Click to win a prize"} class={"capitalize"}/>}
                        style={{width:180}}
                        onClick={()=>history.push("/claim")}
                    />
                </div>
                <h1 className={"primary-gradient-text mt-3 w-100 text-start"}>Redeem your slices </h1>
                <div className={styles.sliceUsage}>
                    <div className={styles.sliceUsageButton}
                         onClick={() => history.push("/buy-gift")}>
                        <Icon size={25} color={"blue"} icon={<Gift />} />
                        <p className={"f-s-2 mt-1"}>Gift</p>
                    </div>
                    <div className={styles.sliceUsageButton}
                         onClick={() => history.push("/donate")}>
                        <Icon size={25} color={"red"} icon={<Heart />} />
                        <p className={"f-s-2 mt-1"}>Donate</p>
                    </div>
                    <div className={styles.sliceUsageButton}
                         onClick={() => {setSelectedSliceBT(true)}}>
                        <Icon size={25} color={"primary"} icon={<SliceIcon />} />
                        <p className={"f-s-2 mt-1"}>Free Pizza</p>
                    </div>
                </div>
            </div>
            {
                selectedSlice && <Modal
                    onClose={()=>setSelectedSlice(undefined)}
                    align={"center"}
                    closeOnOutSideClick
                >
                    <SlicePreview
                        sliceId={selectedSlice}
                        onClose={()=>setSelectedSlice(undefined)}
                    />
                </Modal>
            }
            {
                selectedSlicesBT && <BottomSheet
                    onClose={()=>setSelectedSliceBT(false)}
                    floatHeight
                >
                    <SelectSlice
                        hideMe={()=>setSelectedSliceBT(false)}
                        onSubmit={(slices)=>{
                            setRedeemingSlices(slices);
                            setSelectedSliceBT(false);
                            setConfirmFreePizza(true);
                        }}
                    />
                </BottomSheet>
            }
            {
                confirmFreePizza && <Modal align={'center'} onClose={() => { history.push('/slices') }} >
                    <ConfirmModal
                        title={'You are going to get a free pizza, once the free pizza code is generated, you will lose the slice you selected. Do you want to continue?'}
                        confirmText={'Yes, Buy'}
                        onConfirm={freePizza}
                        onCancel={() => {
                            setConfirmFreePizza(false);
                            setSelectedSliceBT(true);
                        }}
                        inProgress={freePizzaLoading}
                    />
                </Modal>
            }
            {
                showVoucherCode && <Modal
                    align={'center'}
                    onClose={() => { history.push('/slices') }}
                    backgroundImage={Confetti}
                >
                    <RedeemPizza code={voucherCode} onClose={() => {
                        setShowVoucherCode(false);
                    }}/>
                </Modal>
            }
            {
                (showAlert && alertText) && <Modal
                    align={'center'}
                    onClose={() => { setShowAlert(false) }}
                >
                    <Alert
                        title={alertText}
                        confirmText={"ok"}
                        onConfirm={()=>{
                            setShowAlert(false)
                        }}
                    />
                </Modal>
            }
        </>
    );
};

export default Slices;
