import React, { useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Input from "@components/common/form/input";
import Text from "@components/common/text";
import styles from "./buyGift.module.scss";
import {Envelop, Loading, ScanBarcode} from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import buyGift from '@assets/images/buyGift.png';
import buyGiftLine from '@assets/images/buyGiftLine.png';
import Html5QrcodePlugin from '@components/common/qrScanner';
import BottomSheet from '@components/common/bottomSheet';
import {getUserSlices, releaseSlices} from '@webservices/slices';
import SelectSlice from "@components/bottomSheets/selectSlice";
import {Slice} from "@types.d/Slice";
import {failToast} from "@toast/index";
import {getUserInfo, updateGift} from '@webservices/user';
import QRCode from 'react-qr-code';
import {addGift} from "@webservices/gifts";
import Header from "@components/layouts/layoutElements/header";
import Modal from "@components/common/modal";
import Alert from "@components/modals/alert";
import Confetti from "@assets/images/confetti.svg";
import {useHistory} from "react-router-dom";

const BuyGift = () => {

    const history = useHistory();

    const [showQRCode, setShowQRCode] = useState(false);
    const [email, setEmail] = useState<string|undefined>();
    const [myEmail, setMyEmail] = useState<string|undefined>();
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);
    const [showResultModal, setShowResultModal] = useState<boolean>(false);
    const [result, setResult] = useState<string>();
    const [onLoad, setOnLoad] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        (async()=>{
            const userInfo = await getUserInfo();
            setMyEmail(userInfo?.email);
            console.log(myEmail);
        })()
    }, [])

    const sendGift = async (slices:number[]) => {
        if(!email){
            setSelectedSliceBT(false);
            failToast(<Text value={"please enter valid email address"} class={"capitalize"}/>);
            return;
        }
        let userSlices = await getUserSlices();
        const slicesToRelease:Slice[] = [];
        slices.forEach(typeId => {
            const matchSlice = userSlices.findIndex(s=>s.type === typeId);
            if(matchSlice !== -1) {
                slicesToRelease.push(userSlices[matchSlice]);
                userSlices.splice(matchSlice, 1);
            }
        })
        if(slicesToRelease.length < 8) {
            failToast("You dont have enough slices!!");
            return;
        }
        try {
            setOnLoad(true);
            await addGift(email);
            await releaseSlices(slicesToRelease);
            updateGift().catch(e=>console.log(e))
            setSelectedSliceBT(false);
            setShowSuccessModal(true);
            setOnLoad(false);
        } catch (e:any){
            setSelectedSliceBT(false);
            setResult(e.message)
            setShowResultModal(true);
            setOnLoad(false);
        }
    }

    return (
        <>
            <div className={"h-100 d-flex flex-column"}>
                <Header
                    title={"Send a Gift"}
                    backButton
                />
                <div className={"d-flex align-items-center flex-grow-1 p-4 overflow-auto"} style={{flexDirection: 'column'}}>
                    <img src={buyGift} alt="" />
                    <div className={"mt-4"} style={{textAlign: 'center'}}>
                        <Text value={"You can make your friends happy by sending them a PZZA gift."} class={"capitalize"} />
                        <Text value={"Simply enter their email address and we announce them about your love for them."} class={"capitalize"} />
                    </div>
                    <div className={"w-100 mt-4"}>
                        <Input
                            onClick={()=>{}}
                            label={"Friend's email"}
                            labelStyle={{ paddingLeft: '17px' }}
                            placeHolder={"Enter your friend's email"}
                            default={email}
                            onChange={(id, value) => {
                                setEmail(value);
                            }}
                            preIcon={<Icon size={25} color={"none"} icon={<Envelop />} gradient />}
                            button={<CustomButton
                                className={styles.buttonSend}
                                theme={"primary-gradient"}
                                loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                                text={<Text value={"Send"} class={"capitalize"} />}
                                onClick={() => setSelectedSliceBT(true)}
                            />}
                        />
                    </div>
                    <div className={"w-95 mt-4"}>
                        <img src={buyGiftLine} alt="" />
                    </div>
                    {
                        showQRCode && <BottomSheet onClose={() => { setShowQRCode(false) }}>
                            <Html5QrcodePlugin
                                onScan={(value) => {
                                    console.log(value);
                                    setEmail(value);
                                    setShowQRCode(false);
                                }}
                                onError={() => {}}
                            />
                        </BottomSheet>
                    }
                    <div className='mt-5 d-flex justify-content-between flex-column align-items-center' style={{ height: '75px' }}>
                        <Text value={"or scan the QRCode"} />
                        <CustomButton
                            className={styles.buttonSave}
                            theme={"primary-gradient"}
                            loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                            icon={<Icon size={24} color={"none"} icon={<ScanBarcode />} />}
                            text={<Text value={"Scan"} class={"capitalize"} />}
                            onClick={() => { setShowQRCode(true) }}
                        />
                    </div>
                    {
                        myEmail && <div className='mt-2 d-flex justify-content-between flex-column align-items-center' style={{ height: '200px' }}>
                            <Text value={"send my gift here"} />
                            <div style={{margin: '7px', padding: '5px', backgroundColor: 'white'}}>
                                <QRCode
                                    id="qrCode"
                                    value={myEmail!}
                                    size={150}
                                    bgColor={"white"}
                                    fgColor={"black"}
                                />
                            </div>
                        </div>
                    }
                </div>
                {
                    selectedSlicesBT && <BottomSheet
                        onClose={() => setSelectedSliceBT(false)}
                        floatHeight
                    >
                        <SelectSlice
                            hideMe={() => setSelectedSliceBT(false)}
                            onSubmit={sendGift}
                            submitLoading={onLoad}
                        />
                    </BottomSheet>
                }
                {
                    showResultModal && <Modal
                        align={"center"}
                        onClose={()=>{setShowResultModal(false)}}
                    >
                        <Alert
                            title={result||""}
                            confirmText={"Ok"}
                            onConfirm={()=>setShowResultModal(false)}
                        />
                    </Modal>
                }
                {
                    showSuccessModal && <Modal
                        align={"center"}
                        onClose={()=> {
                            setShowSuccessModal(false);
                            history.push('/');
                        }}
                        backgroundImage={Confetti}
                    >
                        <div className={"w-100 d-flex flex-column justify-content-center align-items-center p-4 gap-2"}>
                            <p className={"text-center"}><Text value={"Great! Your gift has been sent to your friend."} class={"capitalize"} /></p>
                            <CustomButton
                                className={styles.buttonSave}
                                theme={"primary-gradient"}
                                loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                                text={<Text value={"Ok"} class={"capitalize"} />}
                                onClick={() => {
                                    history.push('/');
                                }}
                            />
                        </div>
                    </Modal>
                }
            </div>
        </>
    );
};


export default BuyGift;
