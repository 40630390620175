import {failToast, successToast} from "@toast/index";


export function copyToClipboard(text: string, showToast?:boolean) {

    if (!navigator.clipboard){
        let elem = document.createElement("textarea");
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);
    } else{
        navigator.clipboard.writeText(text).then(
            function(){
                showToast && successToast("text copied to clipboard") // success
            })
            .catch(
                function() {
                    showToast && failToast("unknown error") // error
                });
    }

}