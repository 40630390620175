import React, {FunctionComponent, useEffect, useRef} from 'react';
import ReactDOM from "react-dom";
import styles from "./modals.module.scss";
import useOutsideClick from "@hooks/useOutsideClick";

interface ModalProps {
    children?: React.ReactNode
    align?:"top"|"bottom"|"navBottom"|"center"
    onClose:()=>void
    closeOnOutSideClick?:boolean
    backgroundImage?:string
}

const Modal:FunctionComponent<ModalProps> = (props) => {

    const portalContainer = document.getElementById('portals');
    const el = document.createElement('div');

    useEffect(() => {
        if(!portalContainer) return;
        portalContainer.innerHTML = ''; // Clear any existing content in the portal container
        portalContainer.appendChild(el);
        return () => {
            portalContainer.removeChild(el);
        };
    }, [el, portalContainer]);

    const modalRef = useRef<HTMLDivElement|null>(null);

    if(props.closeOnOutSideClick) useOutsideClick(modalRef, props.onClose);

    return ReactDOM.createPortal(
        <div className={`${styles.container} ${props.align && styles[props.align]}`}>
            {props.backgroundImage && <img
                className={styles.backgroundImage}
                src={props.backgroundImage}
                alt={"modal background"}
            />}
            <div className={styles.contents} ref={modalRef}>
                {props.children}
            </div>
        </div>,
        el
    );
};

export default Modal;