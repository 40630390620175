import React, { useState } from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";
import styles from "./donate.module.scss";
import { useHistory } from "react-router-dom";
import { Back, Loading } from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import {getUserSlices, releaseSlices} from '@webservices/slices';
import {Slice} from "@types.d/Slice";
import BottomSheet from "@components/common/bottomSheet";
import SelectSlice from "@components/bottomSheets/selectSlice";
import Modal from "@components/common/modal";
import Confetti from "@assets/images/confetti.svg";
import {failToast} from "@toast/index";
import {addDonate} from "@webservices/donates";
import {updateDonate} from "@webservices/user";

const Donate = () => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);
    const [onDonate, steOnDonate] = useState<boolean>(false);

    const history = useHistory();

    const donate = async (slices:number[]) => {
        steOnDonate(true);
        let userSlices = await getUserSlices();
        const slicesToRelease:Slice[] = [];
        slices.forEach(typeId => {
            const matchSlice = userSlices.findIndex(s=>s.type === typeId);
            if(matchSlice !== -1) {
                slicesToRelease.push(userSlices[matchSlice]);
                userSlices.splice(matchSlice, 1);
            }
        })
        if(slicesToRelease.length < 8) {
            failToast("You dont have enough slices!!");
            return;
        }
        await releaseSlices(slicesToRelease);
        await addDonate();
        updateDonate().catch(e=>console.log(e))
        steOnDonate(false);
        setSelectedSliceBT(false);
        setShowConfirmation(true);
    }

    return (
        <>
            <div className={`"d-flex flex-wrap w-100 p-4 overflow-auto" ${styles.donateMain}`}>
                <div className={styles.donateHeader}>
                    <div onClick={() => {
                        history.push('/');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text text-center my-3"} style={{ paddingLeft: '20px' }}>
                        <Text value={"donate"} class={"capitalize"} />
                    </h1>
                </div>
                <div className={"d-flex mt-5"} style={{ flexGrow: '1', textAlign: 'center'}}>
                    <Text value={" Here is how you can contribute in #BetterWorld activity in PZZA app."} class={"capitalize"} />
                    <Text value={" We use your donations in various ways, to reduce hunger and world reach out the Zero Hunger."} class={"capitalize"} />
                    <Text value={" To learn more check #BetterWorld or PZZA website. www.aiqo.ai"} class={"capitalize"} />
                </div>
                <div className='mt-5 d-flex justify-content-between flex-column align-items-center'>
                    <CustomButton
                        className={styles.buttonSave}
                        theme={"primary-gradient"}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                        text={<Text value={"Donate"} class={"capitalize"} />}
                        onClick={() => setSelectedSliceBT(true)}
                    />
                </div>
                {
                    showConfirmation && <Modal
                        align={"center"}
                        onClose={()=> {
                            setShowConfirmation(false);
                            history.push('/');
                        }}
                        backgroundImage={Confetti}
                    >
                        <div className={"w-100 d-flex flex-column justify-content-center align-items-center p-4 gap-2"}>
                            <p className={"text-center"}><Text value={"Great! Your donation is on its way to make the world a better place to live in #BetterWorld activity."} class={"capitalize"} /></p>
                            <CustomButton
                                className={styles.buttonSave}
                                theme={"primary-gradient"}
                                loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                                text={<Text value={"Ok"} class={"capitalize"} />}
                                onClick={() => {
                                    history.push('/');
                                }}
                            />
                        </div>
                    </Modal>
                }
            </div>
            {
                selectedSlicesBT && <BottomSheet
                    onClose={()=>setSelectedSliceBT(false)}
                    floatHeight
                >
                    <SelectSlice
                        hideMe={()=>setSelectedSliceBT(false)}
                        onSubmit={donate}
                        submitLoading={onDonate}
                    />
                </BottomSheet>
            }
        </>
    );
};


export default Donate;
