import {createContext} from "react";

interface MapContextProps {
    map:google.maps.Map|null
    setMap:(value:google.maps.Map|null)=>void
    googleObject:typeof google|null
    zoom?:number
    setZoom:(value?: number)=>void
}
export const MapContext = createContext<MapContextProps>({
    map:null,
    setMap:()=>{},
    googleObject:null,
    zoom:1,
    setZoom:()=>{}
});