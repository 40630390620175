import React, {FunctionComponent, useEffect, useState} from 'react';
import {LanguageContext} from "../../../contexts/languageContext";
import {dictionaryList} from "../../../constant/languages";

const LanguageProvider:FunctionComponent<any> = (props) => {

    const [dictionary, setDictionary] = useState<{[t:string]:string}>({});

    useEffect(()=>{
        const lang = localStorage.getItem("lang");
        if(lang && dictionaryList[lang]) {
            setDictionary(dictionaryList[lang])
        } else {
            setDictionary(dictionaryList["en"])
        }
    }, [])

    const onLanguageChange = (lang:string) => {
        if(dictionaryList[lang]) {
            localStorage.setItem("lang", lang);
            setDictionary(dictionaryList[lang]);
        }
    }

    const translate = (word:string):string => {
        if(Object.keys(dictionary).length > 0 && dictionary[word.toLowerCase()]) {
            return dictionary[word.toLowerCase()];
        }
        return word;
    }

    return (
        <LanguageContext.Provider value={{
            translate,
            dictionary,
            onLanguageChange
        }}>
            {props.children}
        </LanguageContext.Provider>
    );
};

export default LanguageProvider;