import moment from "moment";

export const  makeFirstLetterUp = (value:string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export const trim = (allowedCharacter:"number"|"latin"|"dateTime"|"date"|"time", value:string, maxLength?:number): string => {
    let trimmed:string = value;
    switch (allowedCharacter) {
        case 'number':
            trimmed = trimmed.replace(/[^0-9.]/g,"");
            break;

        case "date":
            trimmed = trimmed.replace(/[^0-9.-]/g,"");
            let [year, month, day] = trimmed.split("-");
            if(year && year.length > 4){
                month = year.slice(4)
                year = year.slice(0, 4);
            }
            if(month && month.length > 2){
                day = month.slice(2);
                month = month.slice(0, 2);
            }
            if(day && month && month.length < 2){
                month = month === "0" ? "01" : month.padStart(2, "0");
            }
            if(Number(month) > 12){
                month = "12";
            }
            const daysOfMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
            if(Number(day) > daysOfMonth){
                day = daysOfMonth.toString()
            }
            if(day && day.length > 2){
                day = day.slice(0, 2)
            }
            trimmed = `${year}${year.length === 4 && month==="" ? "-" : ""}`;
            if(month) trimmed = `${trimmed}-${month}${month.length === 2 && day==="" ? "-" : ""}`;
            if(day) trimmed = `${trimmed}-${day}`;
            break;

        case 'latin':
            trimmed = trimmed.replace(/[^\x00-\x7F]/g, "");
            break;
    }
    if(maxLength){
        trimmed = trimmed.slice(0, maxLength);
    }
    return trimmed;
}

export const convertEmptyToUndefined = (string:string|undefined|null) : string|undefined => {
    if(string === "" || string === null) return;
    return string;
}