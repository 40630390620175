import {ApiError} from "@types.d/ApiError";
import {getDocuments, updateDocument} from "../../repositories";
import {getAuth} from "firebase/auth";

export interface Pattern {
    id: string
    title:string
    logo:string
    lat: number
    lng:number
    ownerId:string|null
    slices: {
        type: number
        amount: number
    }[]
    reward:string
    createdAt:string
    unlockedAt:string|null
    revenueRate:number
}

export const getLockedPatterns = async ():Promise<Pattern[]> => {
    return getDocuments<Pattern>("Patterns", [{field:"ownerId", operator:"==", value:null}])
}

export const getLockedPatternsByLocation = async (lat:number, lng:number):Promise<Pattern[]> => {
    return getDocuments<Pattern>("Patterns", [
        {field:"ownerId", operator:"==", value:null},
        {field:"lat", operator:"==", value:lat},
        {field:"lng", operator:"==", value:lng},
    ])
}

export const getUnlockedPatterns = async ():Promise<Pattern[]> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getDocuments<Pattern>(
            "Patterns",
            [{field:"ownerId", operator:"==", value:auth.currentUser.uid}]
        );
    }
   return [];
}

export const claimPattern = async (patternId:string, onError?:(err:ApiError)=>void) => {
    const auth = getAuth();
    if(auth.currentUser){
        await updateDocument("Patterns", patternId, {ownerId:auth.currentUser.uid});
    }
}
