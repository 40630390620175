import React, {FunctionComponent} from 'react';
import styles from './style.module.scss';

interface MainLayoutProps {
	children?: React.ReactNode
}

const MainLayout: FunctionComponent<MainLayoutProps> = (props) => {
	return (
		<div className={styles.main}>
			{props.children}
		</div>
	);
};

export default MainLayout;
