import React, {FunctionComponent} from 'react';
import styles from "./unlockedPatternCard.module.scss";
import {Pattern} from "@webservices/patterns";
import MergedSlices from "@components/app/mergedSlices";
import SquareMergedSlices from "@components/app/mergedSlices/square";

interface LockedPatternCardProps {
    pattern:Pattern
}

const UnlockedPatternCard:FunctionComponent<LockedPatternCardProps> = (props) => {

    const getPatterns = ():(number|undefined)[] => {
        let mergedPattern:(number|undefined)[] = [];
            props.pattern.slices.forEach(slice => {
                if(slice.amount === 1){
                    mergedPattern.push(slice.type);
                } else {
                    for (let i=0; i<slice.amount; i++){
                        mergedPattern.push(slice.type);
                    }
                }
            })
        return mergedPattern;
    }

    return (
        <div>
            <div className={"d-flex align-items-center"}>
                <div className={styles.pattern}>
                    <SquareMergedSlices
                        slices={getPatterns()}
                        borderRadius={1}
                    />
                </div>
            </div>
        </div>

    );
};

export default UnlockedPatternCard;
