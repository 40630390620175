import React, {FunctionComponent} from 'react';
import styles from "./patternIconMarker.module.scss";
import ReactDOMServer from "react-dom/server";
import {Pattern} from "@webservices/patterns";

const PatternIconMarkerElement:FunctionComponent<Pattern & {patterns:Pattern["slices"][]}> = (props) => {
    return (
        <div className={styles.container}>
            <img src={props.logo} className={styles.logo} alt={props.id}/>
        </div>
    );
};

export const PatternIconMarker = (Info:Pattern & {patterns:Pattern["slices"][]}):HTMLDivElement => {
    const tag = document.createElement('div');
    tag.innerHTML = ReactDOMServer.renderToString(<PatternIconMarkerElement{...Info}/>);
    return tag;
}
