import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import styles from './menu.module.scss';
import ReactDOM from "react-dom";
import useOutsideClick from "@hooks/useOutsideClick";
import {signOut} from "../../../services/app/userServices";
import { CallCenter, Close, FAQ, Joystick, LogoutRight } from '@components/common/svgIcons';
import Icon from '@components/common/icon';
import { useHistory } from 'react-router-dom';
import { AIQO_WEBSITE_URL } from 'config';

interface MenuProps {
    onClose:()=>void
}
const Menu:FunctionComponent<MenuProps> = (props) => {

    const Container = document.getElementById('portals');
    const element = document.createElement('div');

    const menuRef = useRef<HTMLDivElement>(null);
    const [close, setClose] = useState<boolean>(false);

    const history = useHistory();

    useOutsideClick(menuRef, ()=>{
        setClose(true);
        setTimeout(()=>{
            props.onClose();
        }, 300)
    });

    useEffect(() => {
        if(!Container) return;
        Container.innerHTML = ''; // Clear any existing content in the portal container
        Container.appendChild(element);
        return () => {
            Container.removeChild(element);
        };
    }, [element, Container]);

    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div ref={menuRef} className={`${styles.menu} ${close ? styles.closeMenu : ''}`}>
                <div className={styles.menuContainer}>
                    <div className={styles.topContainer}>
                        <div className={styles.topSection}>
                            <div onClick={() => {
                                window.open(`${AIQO_WEBSITE_URL}`);
                            }}>
                                <Icon size={20} color={"dark-gray"} icon={<Joystick />} />
                                <span>Game tutorial</span>
                            </div>
                            <div onClick={() => {
                                history.push("/faq");
                                setClose(true);
                            }}>
                                <Icon size={20} color={"dark-gray"} icon={<FAQ />} />
                                <span>FAQs</span>
                            </div>
                            <div onClick={() => {
                                history.push("/support");
                                setClose(true);
                            }}>
                                <Icon size={20} color={"dark-gray"} icon={<CallCenter />} />
                                <span>Need help?</span>
                            </div>

                        </div>
                        <div onClick={() => {
                            setClose(true);
                            props.onClose();
                        }}>
                            <Icon size={24} color={"none"} icon={<Close />} gradient />
                        </div>
                    </div>

                    <div className={styles.bottomSection}>
                        <div onClick={() => {
                            signOut();
                        }}>
                            <Icon size={20} color={"dark-gray"} icon={<LogoutRight />} />
                            <span>Sign out</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        element
    );
};

export default Menu;
