import React, {FunctionComponent} from 'react';
import styles from "./divider.module.scss";
import CmsColors from "@types.d/CmsColors";

interface DividerProps {
    direction?:"vertical"|"horizontal"
    color?:CmsColors
    gap?:number
}

const Divider:FunctionComponent<DividerProps> = (props) => {
    return (
        <div
            className={`${props.direction === "vertical" ? styles.vertical : styles.horizontal} ${props.color && "cms-border-"+props.color}`}
            style={{margin: `${props.gap || 1}rem 0`}}
        />
    );
};

export default Divider;
