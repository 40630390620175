import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from "@components/common/map/googleMap/provider/mapContext";
import styles from "./googleMap.module.scss";
import {getLocationPermissionStatus, goToCurrentLocation} from "@components/common/map/googleMap/services";
import BottomSheet from "@components/common/bottomSheet";
import LocationService from "@components/bottomSheets/locationService";
import UnblockLocationService from "@components/bottomSheets/locationService/unblockLocationService";

interface GoogleMapProps {
    zoom?: number
    minZoom?:number
    tilt?:number
    tiltBreakpoints?:{minZoom:number, maxZoom:number, tilt:number}[]
    center?: { lat: number, lng: number }
    onBoundsChanges?: (map: google.maps.Map) => void
    onIdle?: (map: google.maps.Map) => void
    classname?: string
    style?: { [p: string]: any }
    goToCurrentLocation?:boolean
}

const GoogleMap: FunctionComponent<GoogleMapProps> = (props) => {

    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const [showPermissionBT, setShowPermissionBT] = useState<boolean>(false);
    const [showUnblockPermissionBT, setShowUnblockPermissionBT] = useState<boolean>(false);

    const mapOptions = useRef<google.maps.MapOptions>( {
        center: props.center,
        zoom: props.zoom,
        minZoom:props.minZoom,
        mapId: "db56532aa2227380",
        keyboardShortcuts:false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        clickableIcons: false,
        tilt:props.tilt
    })

    const { setMap, googleObject, setZoom } = useContext(MapContext);

    useEffect(() => {
        if (!mapContainerRef.current) return;
        (async () => {
            if (!googleObject) return;
            // create new map
            mapRef.current = new googleObject.maps.Map(mapContainerRef.current!, mapOptions.current);
            setMap(mapRef.current);
            // go to current location if enabled
            if(props.goToCurrentLocation) {
                const status = await getLocationPermissionStatus();
                if(status.state === "prompt"){
                    setShowPermissionBT(true);
                } else if(status.state === "denied") {
                    if(!localStorage.getItem("stopShowUnBlockLocation")){
                        setShowUnblockPermissionBT(true);
                    }
                }
            }
            // add lister on bound Changed
            mapRef.current!.addListener(
                "bounds_changed",
                () => {
                    props.onBoundsChanges && props.onBoundsChanges(mapRef.current!);
                    const curZoom = mapRef.current!.getZoom();
                    setZoom(curZoom);
                    if(props.tiltBreakpoints) {
                        props.tiltBreakpoints.map(tb => {
                            if(curZoom && curZoom > tb.minZoom && curZoom < tb.maxZoom && mapRef.current!.getTilt() !== tb.tilt) {
                                mapRef.current!.setTilt(tb.tilt);
                            }
                        })
                    }
                }
            )
            // add listener on idle
            mapRef.current!.addListener(
                "idle",
                () => {
                    props.onIdle && props.onIdle(mapRef.current!)
                }
            )
        })()
    }, [mapContainerRef.current])

    useEffect(()=>{
        mapRef.current?.setOptions({
            ...mapOptions.current,
            center: props.center,
            zoom: props.zoom
        })
    }, [props.zoom, props.center])

    const location = () => {
        if(!mapRef.current || !googleObject) return ;
        setShowPermissionBT(false);
        goToCurrentLocation(mapRef.current, googleObject);
    }

    return (
        <div className={styles.container}>
            <div
                className={`${styles.map} ${props.classname}`}
                style={props.style || {}}
                ref={mapContainerRef}
                id="map"
            />
            {
                showPermissionBT && <BottomSheet onClose={()=>setShowPermissionBT(false)}>
                    <LocationService
                        onConfirm={location}
                        onCancel={()=>setShowPermissionBT(false)}
                    />
                </BottomSheet>
            }
            {
                showUnblockPermissionBT && <BottomSheet onClose={()=>setShowUnblockPermissionBT(false)}>
                    <UnblockLocationService
                        onConfirm={()=>setShowUnblockPermissionBT(false)}
                    />
                </BottomSheet>
            }
        </div>
    );
};

export default GoogleMap;
