const getRandomInt = (min:number, max:number):number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const buildArray = (min:number, max:number, count:number, start:number, naturalNumbers?:boolean):number[] => {
    let s = start;
    if(s>max) s = min + Math.abs(max - Math.abs(start)) - 1
    if(s<min) s = max - Math.abs(min - Math.abs(start)) + 1;
    if(naturalNumbers && start < 0) s = s-2;
    let array:number[] = [s];
    let counter = 1;
    let index = 1;
    while(counter < count){
        if((s + index) <= max){
            array.push(s + index);
            index++;
        } else {
            array.push(min);
            s = min;
            index = 1;
        }
        counter++;
    }
    return array
}

export const  mergeArraysAndRemoveDuplicates = (arr1:any, arr2:any) => {
    const merged = [...arr1, ...arr2];

    // Create a Map to store objects by a unique key
    const uniqueObjects = new Map();

    // Iterate through merged array and add objects to the Map
    merged.forEach(obj => {
        const key = JSON.stringify(obj);
        uniqueObjects.set(key, obj);
    });

    // Convert Map values back to an array
    return Array.from(uniqueObjects.values());
}

export const buildRandomIntArray = (min:number, max:number, n:number):number[] => {
    const randomArray:number[] = [];
    for (let i = 0; i < n; i++) {
        const randomNum = getRandomInt(min, max);
        randomArray.push(randomNum);
    }
    return randomArray;
}

export const moveItemToFirst = (array:any[], index:number):any[] => {
    if (index !== 0 && index < array.length) {
        const [item] = array.splice(index, 1);
        array.unshift(item);
    }
    return array;
}

export const groupBy = <T>(array:T[], property: string):T[] => {
    const convertedArray:{[K:string]:any} = [...array];
    return convertedArray.reduce((acc:any, obj:any) => {
            const key = obj[property]; 
            acc[key] = [...(acc[key] || []), obj];
            return acc;
        }, {});
}
