import React, {useEffect} from 'react';

const useOutsideClick = (ref:any, action:()=>void) => {

    useEffect(() => {

        function handleClickOutside(event:any) {
            if (ref.current && !ref.current.contains(event.target)) {
                action();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref]);

};

export default useOutsideClick;