import React, {FunctionComponent, useEffect, useState} from 'react';
import {GlobalContext} from "../contexts/GlobalContext";
import { getUserNotifications } from '@webservices/user';



const GlobalProvider:FunctionComponent<any> = (props) => {

    const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

    useEffect(()=>{
        (async () => {
            const userNotifications = await getUserNotifications();
            updateNotifications(userNotifications?.filter(item => item.readTimestamp === null).length || 0);
        })()
    }, [])

    const updateNotifications = (numberToAdd:number) => {
        setUnreadNotifications(unreadNotifications + numberToAdd);
    }

    return (
        <GlobalContext.Provider value={{
            unreadNotifications,
            updateNotifications,

        }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;
