import {Slice} from "@types.d/Slice";
import {getDocumentById, getDocuments, updateDocument} from "../../repositories";
import {Timestamp} from "@firebase/firestore";
import {getAuth} from "firebase/auth";
import {failToast} from "@toast/index";

export const getUserSlices = async ():Promise<Slice[]> => {
    return await getDocuments<Slice>("Slices", [{field:"ownerId", value:localStorage.getItem("sub"), operator:"=="}]);
}

export const assignSliceToUser = async (sliceId:string):Promise<Slice|undefined> => {
    const auth = getAuth();
    if(auth.currentUser){
        try {
            const slice = await getDocumentById<Slice>("Slices", sliceId);
            if(!slice){
                failToast("wrong code!!");
                return;
            }
            if(!slice?.ownerId && !slice.releasedAt){
                await updateDocument("Slices", sliceId, {ownerId:auth.currentUser.uid});
                return slice;
            } else {
                failToast("This slice has already been taken");
            }
        } catch (e) {
            failToast("wrong code!!");
        }
    }
}

export const releaseSlices = async (slices:Slice[]):Promise<void> => {
    try{
        await Promise.all(
            slices.map(async (slice)=>{
                await updateDocument("Slices", slice.id, {
                    ownerId:null,
                    releasedAt:Timestamp.fromDate(new Date())
                })
            })
        )
    } catch (e:any) {
        throw new Error(e)
    }
}
