import React, { FunctionComponent } from 'react';
import styles from "./horizontalSlider.module.scss";

interface HorizontalSliderProps {
    slides: JSX.Element[]
    style?:{[k:string]:any}
    slideWidth?:number
    slideHeight?:number
}

const HorizontalSlider: FunctionComponent<HorizontalSliderProps> = (props) => {
    return (
        <div className={styles.wrapper} style={props.style}>
            <div
                className={styles.slider}>
                {props.slides.map((slide, index)=>{
                    return <div
                        key={index}
                        style={{height:props.slideHeight||"initial", width:props.slideWidth||"initial"}}
                    >{slide}</div>
                })}
            </div>
        </div>
    );
};

export default HorizontalSlider;

