import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import NavigationLayout from "@components/layouts/navigationLayout";
import MainLayout from "@components/layouts/mainLayout";
import Onboarding from "@pages/onboarding";
import Profile from "@pages/profile";
import Slices from "@pages/slices";
import ClaimPatterns from "@pages/claimPatterns";
import Login from "@pages/login";
import PersonalInfo from "@pages/personalInfo";
import VerifyEmail from "@pages/login/verifyEmail";
import MyPatterns from "@pages/myPatterns";
import Donate from "@pages/slices/donate";
import BuyGift from "@pages/slices/buyGift";
import Discount from '@pages/discount';
import Notification from "@pages/notification";
import ScanQR from "@pages/scanQR";
import ClaimPatternsList from "@pages/claimPatterns/claimPatternsList";
import {getAuth} from "firebase/auth";
import FAQ from "@pages/faq";
import Support from "@pages/support";


const Routes = () => {

    const location = useLocation();
    const auth = getAuth();

    return (
        <>
            <Switch>
                {/*no nav & sign out required routes*/}
                <Route exact path={[
                    "/login",
                    "/verify-email",
                ]}>
                    {
                        !auth.currentUser ? <MainLayout>
                            <Switch>
                                <Route path={'/login'} exact component={Login}/>
                                <Route path={'/verify-email'} exact component={VerifyEmail}/>
                            </Switch>
                        </MainLayout> : <Redirect to={'/'}/>
                    }
                </Route>

                {/*no nav & auth required routes*/}
                <Route exact path={[
                    "/claim",
                    "/claim/:location",
                    "/personalInfo",
                    "/buy-gift",
                    "/donate",
                    "/notification",
                    "/faq",
                    "/support"
                ]}>
                    {
                        auth.currentUser ? <MainLayout>
                            <Switch>
                                <Route path={'/claim'} exact component={ClaimPatterns}/>
                                <Route path={'/claim/:location'} exact component={ClaimPatternsList}/>
                                <Route path={'/personalInfo'} exact component={PersonalInfo} />
                                <Route path={'/buy-gift'} exact component={BuyGift} />
                                <Route path={'/donate'} exact component={Donate} />
                                <Route path={'/notification'} exact component={Notification} />
                                <Route path={'/faq'} exact component={FAQ} />
                                <Route path={'/support'} exact component={Support} />
                            </Switch>
                        </MainLayout> : <Redirect to={`/login?ref=${location.pathname.substring(1)}`}/>
                    }
                </Route>
                {/*no nav && no auth*/}
                <Route exact path={[
                    "/onboarding"
                ]}>
                    <MainLayout>
                        <Switch>
                            <Route path={'/onboarding'} exact component={Onboarding}/>
                        </Switch>
                    </MainLayout>
                </Route>

                {/*nav & auth required routes*/}
                <Route exact path={[
                    "/",
                    "/profile",
                    "/scan-qr",
                    "/my-patterns",
                    "/discount",
                ]}>
                    {
                        auth.currentUser ? <NavigationLayout>
                            <Switch>
                                <Route path={'/'} exact component={Slices}/>
                                <Route path={'/profile'} exact component={Profile} />
                                <Route path={'/scan-qr'} exact component={ScanQR} />
                                <Route path={'/my-patterns'} exact component={MyPatterns}/>
                                <Route path={'/discount'} exact component={Discount} />
                            </Switch>
                        </NavigationLayout> : <Redirect to={`/login?ref=${location.pathname.substring(1)}`}/>
                    }
                </Route>
            </Switch>
        </>
    );
};

export default Routes;
