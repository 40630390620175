import React, {useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import {getUserSlices} from "@webservices/slices";
import {getLockedPatternsByLocation, Pattern} from "@webservices/patterns";
import {Slice} from "@types.d/Slice";
import LockedPatternCard from "@components/app/lockedPatternCard";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";
import {useParams} from "react-router-dom";

const ClaimPatternsList = () => {

    const [userSlices, setUserSlices] = useState<{[K:string]:number}>({});
    const [userSlicesRaw, setUserSlicesRaw] = useState<Slice[]>([]);
    const [patterns, setPatterns] = useState<(Pattern)[]>([]);
    const [loadingPatterns, setLoadingPatterns] = useState<boolean>(true);

    const { location } = useParams<{location:string}>();

    useEffect(()=>{
        (async ()=>{
            setLoadingPatterns(true);
            const [lat, lng] = location.split("@");
            setLoadingPatterns(true);
            const [slices, lockedPatterns] = await Promise.all([
                getUserSlices(),
                getLockedPatternsByLocation(Number(lat), Number(lng))
            ])
            setUserSlicesRaw(slices);
            setUserSlices(groupSlices(slices));
            setPatterns(lockedPatterns);
            setLoadingPatterns(false);
        })()
    }, [ location ])

    const groupSlices = (slices:Slice[]):{[K:string]:number} => {
        let sliceTypeArray:{[K:string]:number} = {};
        slices.forEach(item => {
            if(!sliceTypeArray[item.type]){
                sliceTypeArray = {...sliceTypeArray, [item.type]:1};
            } else {
                sliceTypeArray[item.type]++;
            }
        })
        return sliceTypeArray;
    }

    return (
        <div className={"h-100 d-flex flex-column"}>
            <Header
                title={"Special Prizes"}
                backButton
            />
            {
                !loadingPatterns ? <div className={"flex-grow-1 p-4 overflow-auto d-flex flex-column h-100"}>
                    {
                        patterns.map((pattern, index)=>{
                            return <div
                                key={index}
                                className={"d-flex flex-column align-items-center cms-back-glass rounded-4 mb-3 p-3"}
                            >
                                <LockedPatternCard
                                    pattern={pattern}
                                    userSlices={userSlices}
                                    userSlicesRaw={userSlicesRaw}
                                />
                            </div>
                        })
                    }
                </div> : <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                    <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                </div>
            }
        </div>
    );
};

export default ClaimPatternsList;
