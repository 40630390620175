import React, {useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import {getUnlockedPatterns, Pattern} from "@webservices/patterns";
import UnlockedPatternCard from "@components/app/unlockedPatternCard";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

const MyPatterns = () => {

    const [patterns, setPatterns] = useState<Pattern[]>([]);
    const [loadingPatterns, setLoadingPatterns] = useState<boolean>(true);

    useEffect(()=>{
        (async ()=>{
            setLoadingPatterns(true);
            const fetchPattern = await getUnlockedPatterns();
            setPatterns(fetchPattern);
            setLoadingPatterns(false);
        })()
    }, [])

    return (
        <>
            <Header
                title={"Prizes won"}
                menu
            />
            {
                !loadingPatterns ? <div className={"justify-content-between flex-wrap px-4 d-flex mt-3"}>
                        {
                            patterns.map((pattern, index)=>{
                                return <div
                                    key={index}
                                    className={"d-flex flex-column align-items-center cms-back-glass rounded-4 mb-3 p-3 w-50 mb-2"}
                                    style={{flexBasis: "calc(50% - 0.5rem)"}}
                                >
                                    <UnlockedPatternCard pattern={pattern}/>
                                    <p className={"d-flex gap-2 mt-3 text-center"}><Text value={pattern.title} class={"capitalize"}/></p>
                                    <h2 className={"d-flex gap-2 mb-3 text-center"}><Text value={pattern.reward} class={"capitalize"}/></h2>
                                </div>
                            })
                        }
                    </div>
                    : <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                        <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                    </div>
            }
        </>
    );
};

export default MyPatterns;
