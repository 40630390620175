import React, { useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import styles from "./profile.module.scss";
import Header from "@components/layouts/layoutElements/header";
import { Gift, FreePizza, HeartOutline, PersonalInfo, DeleteAccount, Left as ChevronRight } from "@components/common/svgIcons";
import { useHistory } from "react-router-dom";
import CustomButton from '@components/common/customButton';
import {getUserInfo, User} from "@webservices/user";

const Profile = () => {

    const history = useHistory();
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const [statistics, setStatistics] = useState<User["statistics"]|undefined>();

    useEffect(() => {
        (async () => {
            const user = await  getUserInfo();
            setStatistics(user?.statistics)
        })()
    }, [])

    const deleteAccount = () => {

    }

    return (
        <>
            <Header
                title={"Profile"}
                menu
            />
            <div className={"d-flex w-100 p-4 overflow-auto h-100 flex-column"}>
                <div className={styles.profileBoard}>
                    <div className={styles.itemContainer}>
                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<Gift />} />
                                <span>{statistics?.gift || 0}</span>
                            </div>
                            <span>Gift</span>
                        </div>
                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<FreePizza />} />
                                <span>{statistics?.freePizza || 0}</span>
                            </div>
                            <span>Free Pizza</span>
                        </div>
                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<HeartOutline />} />
                                <span>{statistics?.donate || 0}</span>
                            </div>
                            <span>Donate</span>
                        </div>
                    </div>
                </div>
                <div className={`${styles.profileItem}`}>
                    <div className={"mb-3"}>
                        <div onClick={() => {
                            history.push('/personalInfo');}}>
                            <Icon size={18} color={"none"} icon={<PersonalInfo />} gradient />
                            <span>Personal Information</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                                setShowDeleteAccount(true);
                            }}>
                            <Icon size={18} color={"red"} icon={<DeleteAccount />} gradient />
                            <span>Delete Account</span>
                        </div>
                    </div>
                </div>
                {
                    showDeleteAccount &&
                        <div id='modal' className={styles.deleteAccount} onClick={(e) => {
                            if (e.target === e.currentTarget){
                                setShowDeleteAccount(false);
                            }
                        }}>
                            <div className={styles.messageBox}>
                                <div className={styles.titleText}>Delete Account</div>
                                <div className={styles.bodyText}>By deleting your account you will lose everything like order history, but your assests will be available on your wallet.</div>
                                <div className={styles.bodyText}>Do you want to delete your account?</div>
                                <div className={styles.buttonsArea}>
                                    <CustomButton
                                        style={{ color: 'white' }}
                                        theme={"primary-outline"}
                                        text={"Close"}
                                        onClick={() => { setShowDeleteAccount(false) }}
                                    />
                                    <CustomButton
                                        style={{ color: 'white' }}
                                        theme={'red'}
                                        text={"Delete my account"}
                                        onClick={() => deleteAccount()}
                                    />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
};

export default Profile;
