import React, { useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Input from "@components/common/form/input";
import Radio from "@components/common/form/radio";
import Text from "@components/common/text";
import styles from "./personalInfo.module.scss";
import BottomSheet from "@components/common/bottomSheet";
import { useHistory } from "react-router-dom";
import {PersonalInfo as Person, Phone, Envelop, Calender, Down, Back, Loading} from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import { getUserInfo, updateUser, User, UserUpdate } from "@webservices/user";
import SetTime from "@components/bottomSheets/setTime";
import moment from "moment";
import { failToast } from '@toast/index';
import {Timestamp} from "@firebase/firestore";
import Header from "@components/layouts/layoutElements/header";

const PersonalInfo = () => {

    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const [userInfo, setUserInfo] = useState<User|undefined>();
    const [fullName, setFullName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [gender, setGender] = useState<"male"|"female"|"none-binary"|null>();
    const [birthDate, setBirthDate] = useState<string|undefined>(undefined);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const userInfo = await getUserInfo();
            setUserInfo(userInfo);
            setBirthDate(userInfo?.dateOfBirth ? moment(userInfo?.dateOfBirth.seconds*1000).format("DD/MM/YYYY") : "");
            setGender(userInfo?.gender || null);
            setFullName(userInfo?.fullName || "");
            setPhoneNumber(userInfo?.phoneNumber || "");
        })()
    }, [])

    const saveChanges = async () => {
        let updates: UserUpdate|null = {
            fullName,
            phoneNumber,
            dateOfBirth:Timestamp.fromDate(moment(birthDate).toDate()),
            gender
        };
        setSaveLoading(true);
        const updateResult = await updateUser(updates, ()=>{setSaveLoading(false)});

        if(updateResult !== undefined)
            history.push('/profile');
        else
            failToast("Error saving changes!");

        setSaveLoading(false);
    }

    return (
        <>
            <Header
                title={"Personal Information"}
                backButton
            />
            <div className={"d-flex w-100 p-4 overflow-auto h-100 flex-column gap-4"}>
                <Input
                    label={"Full Name"}
                    labelStyle={{ paddingLeft: '17px' }}
                    placeHolder={"Enter your full name"}
                    default={fullName}
                    onChange={(id, value) => {
                        setFullName(value||"");
                    }}
                    preIcon={<Icon size={32} color={"none"} icon={<Person />} gradient />}
                />
                <Input
                    label={"Phone Number"}
                    labelStyle={{ paddingLeft: '17px' }}
                    placeHolder={"Enter your phone number"}
                    default={phoneNumber}
                    onChange={(id, value) => {
                        setPhoneNumber(value||"");
                    }}
                    preIcon={<Icon size={32} color={"none"} icon={<Phone />} gradient />}
                />
                <Input
                    label={"Email"}
                    lock
                    labelStyle={{ paddingLeft: '17px' }}
                    placeHolder={"Enter your email"}
                    default={userInfo?.email}
                    onChange={() => {}}
                    preIcon={<Icon size={25} color={"none"} icon={<Envelop />} gradient />}
                />
                <Input
                    onClick={()=>setShowDatePicker(true)}
                    label={"Birth Date"}
                    labelStyle={{ paddingLeft: '17px' }}
                    placeHolder={"01/01/2000"}
                    default={birthDate}
                    onChange={() => {}}
                    preIcon={<Icon size={25} color={"none"} icon={<Calender />} gradient />}
                    icon={{
                        icon: <Icon size={12} color={"none"} icon={<Down />} gradient />,
                        action: () => { setShowDatePicker(true) }
                    }}
                    lock
                />
                <div style={{paddingLeft:"1rem"}}>
                    <p>Gender</p>
                    <Radio
                        id={"preference"}
                        onChange={(id, value) => {
                            setGender(value);
                        }}
                        default={gender}
                        options={[
                            { title: "Female", value: "female" },
                            { title: "Male", value: "male" },
                            { title: "Rather not to say", value: "ratherNot" },
                        ]}
                    />
                </div>
                <div className='mt-5 d-flex align-items-center justify-content-center'>
                    <CustomButton
                        className={styles.buttonSave}
                        theme={"primary-gradient"}
                        loading={saveLoading}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                        text={<Text value={"Save"} class={"capitalize"} />}
                        onClick={() => {
                            saveChanges().catch();
                        }}
                    />
                </div>
            </div>
            {
                showDatePicker &&
                <BottomSheet onClose={() => setShowDatePicker(false)}>
                    <SetTime
                        id={"deliveryTime"}
                        format={"DD/MM/YYYY"}
                        default={birthDate}
                        onCancel={()=>{
                            setShowDatePicker(false);}}
                        onSave={(id, value)=>{
                            console.log(value);
                            setBirthDate(value);
                            setShowDatePicker(false);
                        }}
                        pickerType={"date"}
                    />
                </BottomSheet>
            }
        </>
    );
};


export default PersonalInfo;
