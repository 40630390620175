import React, {useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import {getLockedPatterns, Pattern} from "@webservices/patterns";
import GoogleMap from "@components/common/map/googleMap";
import { DEFAULT_MAP_CENTER } from 'config';
import GoogleMapProvider from "@components/common/map/googleMap/provider";
import Marker from "@components/common/map/googleMap/marker";
import {PatternMarker} from "@components/common/map/markers/patternMarker";
import CurrentLocation from "@components/common/map/googleMap/controls/currentLocation";
import {useHistory} from "react-router-dom";
import {PatternIconMarker} from "@components/common/map/markers/patternIconMarker";

const ClaimPatterns = () => {

    const history = useHistory();

    const [patterns, setPatterns] = useState<(Pattern & {patterns:Pattern["slices"][]})[]>([]);

    useEffect(()=>{
        (async ()=>{
            const lockedPatterns = await getLockedPatterns();
            setPatterns(groupPatterns(lockedPatterns));
        })()
    }, [])

    const groupPatterns = (patterns:Pattern[]):(Pattern & {patterns:Pattern["slices"][]})[] => {
        const fields: ("lat"|"lng")[] = ["lat", "lng"]
        const results =  patterns.reduce((result:any, item) => {
            const key = fields.map(field => item[field]).join('-');
            if (!result[key]) {
                result[key] = {...item, patterns:[item.slices]};
            } else {
                result[key] = {...result[key], patterns:[...result[key].patterns, item.slices]};
            }
            return result;
        }, {});
        return Object.keys(results).map(key => results[key]);
    }

    return (
        <div className={"h-100 d-flex flex-column"}>
            <Header
                title={"Special prizes"}
                backButton
                float
            />
            <div className={"flex-grow-1 overflow-auto d-flex flex-column h-100 cms-back-white"}>
                <GoogleMapProvider>
                    <div style={{height:"100%"}}>
                        <GoogleMap
                            zoom={ 16}
                            center={DEFAULT_MAP_CENTER}
                            style={{ flex: 1 }}
                        />
                        <CurrentLocation type={"button"}/>
                        <Marker
                            markersBreakPoints={[
                                { zoomMin: 0, zoomMax: 15 },
                                { zoomMin: 16, zoomMax: 30 }
                            ]}
                            markers={patterns.map(pattern=>{
                            return {
                                id: pattern.id,
                                position: { lat: pattern.lat, lng: pattern.lng },
                                advanceMarker:[PatternIconMarker, PatternMarker],
                                onClick:()=>{history.push(`claim/${pattern.lat}@${pattern.lng}`)},
                                info:pattern
                            }
                        })}/>
                    </div>
                </GoogleMapProvider>
            </div>
        </div>
    );
};

export default ClaimPatterns;
