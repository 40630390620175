import React, { ReactNode, useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";
import styles from "./discount.module.scss";
import {CopyRedeemCode, Loading} from "@components/common/svgIcons";
import CopyClipboard from '@components/common/copyClipboard';
import VoucherPZZA from '../../../src/assets/images/voucherPizza.png';
import {getUserVouchers, Voucher} from "@webservices/vouchers";
import Header from "@components/layouts/layoutElements/header";


const Discount = () => {

    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [onLoad, setOnLoad] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            setOnLoad(true);
            const userVouchers = await getUserVouchers();
            setVouchers(userVouchers);
            setOnLoad(false);
        })()
    }, [])

    const generateVoucherList = () => {
        return (
            vouchers.filter(item => item.status === 1).map((item) => {
                return(
                    <div className={`${styles.couponContainer} ${styles.bump}`}>
                        <div className={styles.dataContainer}>
                            <div className={styles.imageContainer}>
                                <img src={VoucherPZZA} className={styles.generalPizza} alt={VoucherPZZA}/>
                            </div>
                            <div className={styles.detailsContainer}>
                                <Text value={"Your discount on next order"} class={"capitalize"} />
                                <div className={styles.detailsBottom}>
                                    <div className={styles.discount}>
                                        <Text value={""} class={"capitalize"} />
                                    </div>
                                    <div className={styles.redeemCode}>
                                        <CopyClipboard text={item.code} />
                                        <Icon size={24} color={"light-gray"} icon={<CopyRedeemCode />} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        );
    }

    return (
        <>
            <div className={"h-100 d-flex flex-column"}>
                <Header
                    title={"Vouchers"}
                    menu
                />
                <div className={"d-flex h-100 p-4 overflow-auto"} style={{flexDirection: 'column'}}>
                {
                    onLoad ? <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                            <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                        </div> : vouchers.filter(item => item.status === 1).length === 0 ?
                            <div className={styles.noDiscount}>
                                <Text value={"You have not received any discount codes yet!"} class={"capitalize"} />
                            </div>
                    : generateVoucherList()
                }
                </div>
            </div>
        </>
    );
};


export default Discount;
