import {FirestoreTimestamp} from "@types.d/FirestoreTimestamp";
import {getAuth} from "firebase/auth";
import {getDocuments, insertDocument} from "../../repositories";
import {Timestamp} from "@firebase/firestore";
import {failToast} from "@toast/index";
import {getUserInfoByEmail} from "@webservices/user";
import {setVoucherForGift} from "@webservices/vouchers";

export interface Gift {
    id: string
    userId: string
    targetUser:string|null
    targetEmail: string
    voucherId:string|null
    status:number
    createdAt: FirestoreTimestamp|null
}


export const getUserGifts = async ():Promise<Gift[]> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getDocuments<Gift>("Gifts", [{field: "userId", operator: "==", value: auth.currentUser.uid}]);
    }
    return [];
}

export const addGift = async (email:string):Promise<void> => {
    const auth = getAuth();
    if(!auth.currentUser) {
        throw new Error("You are not logged in!");
    }
    const user = await getUserInfoByEmail(email);
    if(auth.currentUser.uid === user?.id){
        throw new Error("You Entered Your Own Email!!");
    }
    if(user){
        const voucher = await setVoucherForGift(user.id);
        if(voucher){
            await insertDocument(
                "Gifts",
                undefined,
                {
                    userId: auth.currentUser.uid,
                    targetUser:user.id,
                    targetEmail: email,
                    voucherId:voucher,
                    status:1,
                    createdAt: Timestamp.fromDate(new Date())
                }
            );
        } else {
            throw new Error("There is no voucher now, please try later!");
        }
    } else {
        await insertDocument(
            "Gifts",
            undefined,
            {
                userId: auth.currentUser.uid,
                targetUser:null,
                targetEmail: email,
                voucherId:null,
                status:0,
                createdAt: Timestamp.fromDate(new Date())
            }
        );
    }
}
