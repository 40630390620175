import React, {FunctionComponent, useLayoutEffect, useRef, useState} from 'react';
import styles from "./vc.module.scss";

interface VerticalCarouselProps {
    elementHeight:number
    array:(string|number)[]
    changeSlide:(dir:-1|1)=>void
}

const VerticalCarousel:FunctionComponent<VerticalCarouselProps> = (props) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLUListElement>(null);
    const startYPos = -3 * props.elementHeight;

    const yPos = useRef<number|null>(startYPos);
    const startClickedY = useRef<number|null>(null);

    const [yPosState, setYPosState] = useState<number>(startYPos)

    useLayoutEffect(()=>{
        containerRef.current!.addEventListener("touchstart", HandleTouchStart)
        containerRef.current!.addEventListener("touchend", HandleTouchEnd)
        containerRef.current!.addEventListener("touchmove", HandleTouchMove)
        containerRef.current!.addEventListener("mousedown", HandleClickStart)
        containerRef.current!.addEventListener("mouseup", HandleClickEnd)
        containerRef.current!.addEventListener("mousemove", HandleMouseMove)
        return () => {
            containerRef.current!.removeEventListener("touchstart", HandleTouchStart)
            containerRef.current!.removeEventListener("touchend", HandleTouchEnd)
            containerRef.current!.removeEventListener("touchmove", HandleTouchMove)
            containerRef.current!.removeEventListener("mousedown", HandleClickStart)
            containerRef.current!.removeEventListener("mouseup", HandleClickEnd)
            containerRef.current!.removeEventListener("mousemove", HandleMouseMove)
        }
    }, [])

    const HandleTouchStart = (e:TouchEvent) => {
        e.preventDefault();
        startClickedY.current = e.touches[0].clientY;
    }

    const HandleTouchEnd = (e:TouchEvent) => {
        e.preventDefault();
        yPos.current = -120;
        setYPosState(-120);
    }

    const HandleTouchMove = (e:TouchEvent) => {
        e.preventDefault();
        if(!startClickedY) return;
        const movementY = startClickedY.current! - e.touches[0].clientY;
        const top = yPos.current! - movementY
        if(-120-top >= 40){
            props.changeSlide(1);
            yPos.current = -120;
            setYPosState(-120);
        } else if(-120-top <= -40) {
            props.changeSlide(-1);
            yPos.current = -120;
            setYPosState(-120);
        } else {
            yPos.current = top;
            setYPosState(top);
        }
        startClickedY.current = e.touches[0].clientY;
    }

    const HandleClickStart = (e:MouseEvent) => {
        e.preventDefault();
        startClickedY.current = e.clientY;
    }

    const HandleClickEnd = (e:MouseEvent) => {
        e.preventDefault();
        yPos.current = -120;
        setYPosState(-120);
        startClickedY.current = null;
    }

    const HandleMouseMove = (e:MouseEvent) => {
        e.preventDefault();
        console.log()
        if(!startClickedY.current) return;
        const movementY = startClickedY.current! - e.clientY;
        const top = yPos.current! - movementY
        if(-120-top >= 40){
            props.changeSlide(1);
            yPos.current = -120;
            setYPosState(-120);
        } else if(-120-top <= -40) {
            props.changeSlide(-1);
            yPos.current = -120;
            setYPosState(-120);
        } else {
            yPos.current = top;
            setYPosState(top);
        }
        startClickedY.current = e.clientY;
    }

    return (
        <div
            className={styles.container}
            ref={containerRef}
        >
            <ul ref={sliderRef} style={{top:yPosState}}>
                {
                    props.array.map((i, index) => {
                        return <li
                            key={index}
                            className={index === 4 ? styles.active : ""}
                        >
                            <p>{String(i).padStart(2, '0')}</p>
                        </li>
                    })
                }
            </ul>
        </div>
    );

};

export default VerticalCarousel;
