import {failToast} from "@toast/index";
import Text from "@components/common/text";
import React from "react";

export const handleFirebaseError = (error:any, callback?:()=>void) => {
    if(callback) {
        callback();
        return;
    }
    let toastText:string;
    switch (error.code){
        case "auth/wrong-password":
            toastText = "the password you entered is incorrect.";
            break;

        case "auth/too-many-requests":
            toastText = "try again in few moment!";
            break;

        case "auth/invalid-phone-number":
            toastText = "invalid phone number";
            break;

        case "auth/invalid-verification-code":
            toastText = "the entered code is incorrect.";
            break;

        case "auth/user-not-found":
            toastText = "email not found";
            break;

        case "auth/email-already-in-use":
            toastText = "email address already in use";
            break;

        default:
            toastText = "unknown error";
            console.log(error.code)
    }
    failToast(<p><Text value={toastText} class={"capitalize"}/></p>);
}