import {FirestoreTimestamp} from "@types.d/FirestoreTimestamp";
import {getAuth} from "firebase/auth";
import {getDocuments, getOneDocument, updateDocument} from "../../repositories";
import {Timestamp} from "@firebase/firestore";

export interface Voucher {
    id: string
    userId: string
    expirationDate: FirestoreTimestamp|null
    code: string
    status: number
    createdAt: string
    updatedAt: string
}


export const getUserVouchers = async ():Promise<Voucher[]> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getDocuments<Voucher>("Vouchers", [{field: "userId", operator: "==", value: auth.currentUser.uid}]);
    }
    return [];
}

export const setVoucher = async ():Promise<string|undefined> => {
    const auth = getAuth();
    if(auth.currentUser){
        const availableVoucher = await getOneDocument<Voucher>("Vouchers", [{field:"userId", operator:"==", value:null},{field:"status", operator:"==", value:0}]);
        if(availableVoucher){
            await updateDocument<Voucher>(
                "Vouchers",
                availableVoucher.id,
                {
                    userId:auth.currentUser.uid,
                    status:1,
                    updatedAt:Timestamp.fromDate(new Date())
                }
            );
            return availableVoucher.code;
        } else {
            throw new Error("There is no voucher now, please try later!");
        }
    }
}

export const setVoucherForGift = async (userId:string):Promise<string|undefined> => {
    const auth = getAuth();
    if(auth.currentUser){
        const availableVoucher = await getOneDocument<Voucher>("Vouchers", [{field:"userId", operator:"==", value:null},{field:"status", operator:"==", value:0}]);
        if(availableVoucher){
            await updateDocument<Voucher>(
                "Vouchers",
                availableVoucher.id,
                {
                    userId,
                    status:1,
                    updatedAt:Timestamp.fromDate(new Date())
                }
            );
            return availableVoucher.id;
        }
    }
}
