import { toast } from 'react-toastify';
import React from "react";

export const successToast = (text:string|React.ReactElement) => {
    toast.success(() => <div className={"app-toast"}>{text}</div>, {
        style:{background:"#000"}
    });
}

export const failToast =  (text:string|React.ReactElement) => {
    toast.error(() => <div className={"app-toast"}>{text}</div>, {
        style:{background:"#000"}
    });
}