import en from "./en.json"
import fi from "./fi.json"
import se from "./se.json"
import ukFlag from "@assets/icons/uk.svg";
import fiFlag from "@assets/icons/fi.svg";
import seFlag from "@assets/icons/se.svg";
export const dictionaryList: {[p:string]:{[p:string] : string}} = {
    en: en as {[p:string] : string},
    fi: fi as {[p:string] : string},
    se: se as {[p:string] : string}
};

export const languagesList = [
    {title:"english", value:"en", img:ukFlag},
    {title:"finnish", value:"fi", img:fiFlag},
    {title:"swedish", value:"se", img:seFlag},
]
