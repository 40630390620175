import React from 'react';
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import App from "./app";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
        const message = event.data;

        if (message.type === 'sw-update') {
            const newWorker = message.payload;

            // Notify the user about the update
            // You can use a snackbar, modal, or any other UI element to show the notification
            alert('A new version is available. Click OK to refresh.');

            // Skip waiting to activate the new service worker
            newWorker.postMessage({ action: 'skipWaiting' });
        }
    });

    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('Service Worker registered:', registration);
            })
            .catch(error => {
                console.error('Error registering Service Worker:', error);
            });
    });
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
