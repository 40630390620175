import {ApiError} from "@types.d/ApiError";
import {
    getDocumentById,
    getOneDocument,
    getSubDocuments,
    insertDocument,
    updateDocument
} from "../../repositories";
import { Timestamp } from "@firebase/firestore";
import {FirestoreTimestamp} from "@types.d/FirestoreTimestamp";
import {getAuth} from "firebase/auth";
import { increment } from "firebase/firestore";


export interface User {
    id:string
    fullName?: string|null
    email:string
    walletId?:string|null
    phoneNumber?: string|null
    dateOfBirth?: FirestoreTimestamp|null
    gender?: "male"|"female"|"none-binary"|null
    statistics:{
        freePizza:number
        donate:number
        gift:number
    }|null
    createdAt: FirestoreTimestamp
}

export interface UserUpdate {
    fullName?: string|null
    phoneNumber?: string|null
    dateOfBirth?: FirestoreTimestamp|null
    gender?: "male"|"female"|"none-binary"|null
    fcmToken?: string|null
}

export interface UserNotification{
    notificationId: string,
    readTimestamp: null,
    messageTitle: string,
    messageBody: string,
    created_at: string,
}

export const getUserInfo = async (onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getDocumentById<User>(
            "Users",
            auth.currentUser.uid
        );
    }
}

export const getUserInfoByEmail = async (email:string, onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getOneDocument<User>(
            "Users",
            [{field:"email", operator:"==", value:email}]
        );
    }
}

export const registerUser = async (
    credential:{ userUId: string, email: string },
    onError?:(err:ApiError)=>void
):Promise<User|undefined> => {
    return insertDocument("Users", credential.userUId, {
        email:credential.email,
        createdAt: Timestamp.fromDate(new Date())
    }) ;
}

export const updateUser = async (
    userInfo:UserUpdate,
    onError?:(err:ApiError)=>void
):Promise<any> => {
    const auth = getAuth();
    if(auth.currentUser){
        return updateDocument("Users", auth.currentUser.uid, userInfo);
    }
}

export const updateFreePizza = async (
    onError?:(err:ApiError)=>void
):Promise<any> => {
    const auth = getAuth();
    if(auth.currentUser){
        return updateDocument("Users", auth.currentUser.uid, {
            "statistics.freePizza": increment(1)
        });
    }
}

export const updateDonate = async (
    onError?:(err:ApiError)=>void
):Promise<any> => {
    const auth = getAuth();
    if(auth.currentUser){
        return updateDocument("Users", auth.currentUser.uid, {
            "statistics.donate": increment(1)
        });
    }
}

export const updateGift = async (
    onError?:(err:ApiError)=>void
):Promise<any> => {
    const auth = getAuth();
    if(auth.currentUser){
        return updateDocument("Users", auth.currentUser.uid, {
            "statistics.gift": increment(1)
        });
    }
}

export const getUserNotifications = async (onError?:(err:ApiError)=>void):Promise<UserNotification[]|null> => {
    const userNotifications =  await getSubDocuments<UserNotification>("Notifications");
    console.log(userNotifications);
    return userNotifications || null;
}

export const setNotificationRead = async (notificationId:string, onError?:(err:ApiError)=>void):Promise<any> => {
    // const notification = await httpPatch<any, Message<any>>(
    //     `${API_URL}/${USER_API_URL}notifications/setRead/${notificationId}`,
    //     undefined,
    //     undefined,
    //     onError
    // )
    // return notification?.data;
}
