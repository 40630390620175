import React from 'react';
import Header from "@components/layouts/layoutElements/header";


const FAQ = () => {

    return (
        <>
            <div className={"h-100 d-flex flex-column"}>
                <Header
                    title={"FAQs"}
                    backButton
                />
                <div
                    className={"d-flex h-100 p-4 overflow-auto"}
                    style={{flexDirection: 'column'}}
                >
                    <h1 className={"mb-2"}>1.How does the Pizza Loyalty Program work?</h1>
                    <p className={"mb-4"}>Scan the QR code on participating pizza boxes to collect virtual pizza slices in the app. Each scan gives you a random slice that’s added to your collection.</p>

                    <h1 className={"mb-2"}>How many slices do I need for a free pizza?</h1>
                    <p className={"mb-4"}>Collect 8 slices in your wallet to redeem a free pizza from the participating pizza shop.</p>

                    <h1 className={"mb-2"}>What can I do with the pizza slices in my slices?</h1>
                    <p>- Save them to redeem a free pizza when you have 8 slices.</p>
                    <p>- Donate a slice to charity.</p>
                    <p className={"mb-4"}>- Send a slice to a friend as a gift.</p>

                    <h1 className={"mb-2"}>Can I win a prize by collecting certain slices?</h1>
                    <p className={"mb-4"}>Yes! If you collect a matching set of 8 slices for a specific pizza shop, you’re eligible to claim a special prize. Keep an eye out for limited-time matching challenges.</p>

                    <h1 className={"mb-2"}>How do I donate or gift a slice to someone?</h1>
                    <p className={"mb-4"}>Go to your Slices, select the slice you want to share, and choose either "Donate" or "Gift to Friend." Enter your friend’s email to send it.</p>

                    <h1 className={"mb-2"}>Are all QR codes unique?</h1>
                    <p className={"mb-4"}>Yes, each QR code scan gives you a unique, random slice. You may collect different slices each time you scan, increasing your chance to complete a set.</p>

                    <h1 className={"mb-2"}>Where can I check my slice collection and rewards?</h1>
                    <p className={"mb-4"}>Visit the "my slices" section in the app to view your current pizza slices, track your progress toward a free pizza, and see if you qualify for any special prizes.</p>

                    <h1 className={"mb-2"}>Is there an expiration date for my slices?</h1>
                    <p className={"mb-4"}>Collected slices remain in your wallet( my Slices) , but some promotions or special prizes may have limited-time availability.</p>
                </div>
            </div>
        </>
    );
};


export default FAQ;
