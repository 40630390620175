import React, {FunctionComponent} from 'react';
import Icon from "@components/common/icon";
import {CurrentLocation} from "@components/common/svgIcons";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";

interface LocationServiceProps {
    onConfirm: ()=>void
    onCancel: ()=>void
}

const LocationService:FunctionComponent<LocationServiceProps> = (props) => {
    return (
        <div>
            <div className={"d-flex gap-3"}>
                <Icon size={25} color={"dark-gray"} icon={<CurrentLocation/>}/>
                <p>In order to find parking near you, please turn on you’re location.</p>
            </div>
            <div className={"d-flex justify-content-center gap-3 pt-4"}>
                <CustomButton
                    text={<Text value={"turn on"}/>}
                    onClick={props.onConfirm}
                    theme={"primary"}
                />
                <CustomButton
                    theme={"white"}
                    text={"cancel"}
                    onClick={props.onCancel}
                />
            </div>
        </div>
    );
};

export default LocationService;