import {FirestoreTimestamp} from "@types.d/FirestoreTimestamp";
import {getAuth} from "firebase/auth";
import {getDocuments, insertDocument} from "../../repositories";
import {Timestamp} from "@firebase/firestore";
import {failToast} from "@toast/index";

export interface Donate {
    id: string
    userId: string
    status: number
    createdAt: FirestoreTimestamp|null
}


export const getUserDonates = async ():Promise<Donate[]> => {
    const auth = getAuth();
    if(auth.currentUser){
        return await getDocuments<Donate>("Donates", [{field: "userId", operator: "==", value: auth.currentUser.uid}]);
    }
    return [];
}

export const addDonate = async ():Promise<void> => {
    const auth = getAuth();
    if(auth.currentUser){
        await insertDocument(
            "Donates",
            undefined,
            {
                userId:auth.currentUser.uid,
                status:1,
                createdAt:Timestamp.fromDate(new Date())
            }
        );
    }
}
