import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import styles from "./navigationLayout.module.scss"
import Icon from "@components/common/icon";
import {
    PersonalInfo,
    Peer,
    PizzaGame, ScanBarcode, Pattern, Voucher
} from "@components/common/svgIcons";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import navbarBack from "@assets/icons/navbar.svg"
import navGameBtn from "@assets/icons/game-nav.svg"
import navGameActiveBtn from "@assets/icons/game-nav-active.svg"

interface NavigationLayoutProps {
    children?: React.ReactNode
}

const NavigationLayout:FunctionComponent<NavigationLayoutProps> = (props) => {

    const history = useHistory();

    const navbar = [
        {
            label: <Text value={"scan QR"} class={"capitalize"}/>,
            mainIcon: <ScanBarcode/>,
            path:"/scan-qr",
        },
        {
            label: <Text value={"Prizes won"} class={"capitalize"}/>,
            mainIcon: <Pattern/>,
            path:"/my-patterns",
        },
        {
            label: <Text value={"game"} class={"capitalize"}/>,
            mainIcon: <Peer/>,
            path:"/"
        },
        {
            label: <Text value={"vouchers"} class={"capitalize"}/>,
            mainIcon: <Voucher/>,
            path:"/discount"
        },
        {
            label: <Text value={"profile"} class={"capitalize"}/>,
            mainIcon: <PersonalInfo/>,
            path:"/profile"
        }
    ]

    const [path, setPath] = useState<string>();

    useEffect(()=>{
        history.listen(location => setPath(location.pathname))
    }, [])

    const Navbar = useMemo(()=>{
        return navbar.map((nav, index)=>{
            if(index === 2){
                return <div className={styles.button} key={index}/>
            }
            return <div
                className={`${styles.button}`}
                onClick={()=>{history.push(nav.path)}}
                key={index}
            >
                {
                    history.location.pathname === nav.path ?
                        <Icon size={20} color={"none"} icon={nav.mainIcon} gradient/> :
                        <Icon size={20} color={"dark-gray"} icon={nav.mainIcon} />
                }
                <p className={`${history.location.pathname === nav.path ? "primary-gradient-text f-s-2" : "f-s-2"}  mt-2`}>
                    {nav.label}
                </p>
            </div>
        })
    }, [path])

    return (
        <div className={styles.container}>
            <div className={styles.contentBack}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
            <div className={styles.nav}>
                <div className={styles.navbarBackLeft}/>
                <img
                    src={navbarBack}
                    className={styles.navBackground}
                    alt={"navbar background"}
                />
                <div className={styles.navbarBackRight}/>
                <div
                    className={styles.navGameBtn}
                    onClick={()=>history.push("/")}
                >
                    {
                        history.location.pathname === "/" ?
                            <Icon size={30} color={"black"} icon={<PizzaGame/>}/> :
                            <Icon size={30} color={"none"} icon={<PizzaGame/>} gradient/>
                    }
                    <img
                        src={history.location.pathname === "/" ? navGameActiveBtn : navGameBtn}
                        alt={"navbar game btn background"}
                    />
                </div>

                {Navbar}
            </div>
        </div>
    );
};

export default NavigationLayout;
