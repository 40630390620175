import Kinza from "@assets/icons/slices/kinza.svg";
import Pinja from "@assets/icons/slices/pinja.svg";
import Quenza from "@assets/icons/slices/quenza.svg";
import Pizzer from "@assets/icons/slices/pizzer.svg";
import Papa from "@assets/icons/slices/papa.svg";
import Gadget from "@assets/icons/slices/gadget.svg";
import Eliza from "@assets/icons/slices/eliza.svg";
import Poliza from "@assets/icons/slices/poliza.svg";
import Vampaza from "@assets/icons/slices/vampaza.svg";
import Papinz from "@assets/icons/slices/papinz.svg";
import Babiza from "@assets/icons/slices/babiza.svg";
import Thing from "@assets/icons/slices/thing.svg";
import Woody from "@assets/icons/slices/woody.svg";
import Nikiza from "@assets/icons/slices/nikiza.svg";
import Cuoco from "@assets/icons/slices/cuoco.svg";
import Fireza from "@assets/icons/slices/fireza.svg";
import Ariel from "@assets/icons/slices/ariel.svg";
import Amirza from "@assets/icons/slices/amirza.svg";
import Topela from "@assets/icons/slices/topela.svg";
import Hawaiana from "@assets/icons/slices/hawaiana.svg";

import KinzaFlat from "@assets/icons/slices/kinza-flat.svg";
import QuenzaFlat from "@assets/icons/slices/quenza-flat.svg";
import PizzerFlat from "@assets/icons/slices/pizzer-flat.svg";
import PinjaFlat from "@assets/icons/slices/pinja-flat.svg";
import PapaFlat from "@assets/icons/slices/papa-flat.svg";
import GadgetFlat from "@assets/icons/slices/gadget-flat.svg";
import ElizaFlat from "@assets/icons/slices/eliza-flat.svg";
import PolizaFlat from "@assets/icons/slices/poliza-flat.svg";
import VampazaFlat from "@assets/icons/slices/vampaza-flat.svg";
import PapinzFlat from "@assets/icons/slices/babiza-flat.svg";
import BabizaFlat from "@assets/icons/slices/papinz-flat.svg";
import ThingFlat from "@assets/icons/slices/thing-flat.svg";
import WoodyFlat from "@assets/icons/slices/woody-flat.svg";
import NikizaFlat from "@assets/icons/slices/nikiza-flat.svg";
import CuocoFlat from "@assets/icons/slices/cuoco-flat.svg";
import FirezaFlat from "@assets/icons/slices/fireza-flat.svg";
import ArielFlat from "@assets/icons/slices/ariel-flat.svg";
import AmirzaFlat from "@assets/icons/slices/amirza-flat.svg";
import TopelaFlat from "@assets/icons/slices/topela-flat.svg";
import HawaianaFlat from "@assets/icons/slices/hawaiana-flat.svg";

export const slices:{
    id:number
    title:string
    icon:string
    flatIcon:string
    properties:string[]
    supply:number
    background:string
}[] = [
    {
        id:1,
        title:"kinza",
        icon:Kinza,
        flatIcon:KinzaFlat,
        properties:[],
        supply:6,
        background:"#eefffa"
    },
    {
        id:2,
        title:"quenza",
        icon:Quenza,
        flatIcon:QuenzaFlat,
        properties:[],
        supply:12,
        background:"#fffcc0"
    },
    {
        id:3,
        title:"pizzer",
        icon:Pizzer,
        flatIcon:PizzerFlat,
        properties:[],
        supply:3,
        background:"#ffe9ec"
    },
    {
        id:4,
        title:"pinja",
        icon:Pinja,
        flatIcon:PinjaFlat,
        properties:[],
        supply:15,
        background:"#f7f7f7"
    },
    {
        id:5,
        title:"papa",
        icon:Papa,
        flatIcon:PapaFlat,
        properties:[],
        supply:30,
        background:"#ffebd2"
    },
    {
        id:6,
        title:"gadget",
        icon:Gadget,
        flatIcon:GadgetFlat,
        properties:[],
        supply:45,
        background:"#ffefe1"
    },
    {
        id:7,
        title:"eliza",
        icon:Eliza,
        flatIcon:ElizaFlat,
        properties:[],
        supply:60,
        background:"#f7f0ff"
    },
    {
        id:8,
        title:"poliza",
        icon:Poliza,
        flatIcon:PolizaFlat,
        properties:[],
        supply:75,
        background:"#f8feff"
    },
    {
        id:9,
        title:"vampaza",
        icon:Vampaza,
        flatIcon:VampazaFlat,
        properties:[],
        supply:100,
        background:"#e2d6d5"
    },
    {
        id:10,
        title:"papinz",
        icon:Papinz,
        flatIcon:PapinzFlat,
        properties:[],
        supply:130,
        background:"#efe1e8"
    },
    {
        id:11,
        title:"babiza",
        icon:Babiza,
        flatIcon:BabizaFlat,
        properties:[],
        supply:160,
        background:"#f9f9f9"
    },
    {
        id:12,
        title:"thing",
        icon:Thing,
        flatIcon:ThingFlat,
        properties:[],
        supply:190,
        background:"#eaead2"
    },
    {
        id:13,
        title:"woody",
        icon:Woody,
        flatIcon:WoodyFlat,
        properties:[],
        supply:220,
        background:"#f4ebd8"
    },
    {
        id:14,
        title:"nikiza",
        icon:Nikiza,
        flatIcon:NikizaFlat,
        properties:[],
        supply:270,
        background:"#fdf1fd"
    },
    {
        id:15,
        title:"cuoco",
        icon:Cuoco,
        flatIcon:CuocoFlat,
        properties:[],
        supply:320,
        background:"#f1edfb"
    },
    {
        id:16,
        title:"fireza",
        icon:Fireza,
        flatIcon:FirezaFlat,
        properties:[],
        supply:370,
        background:"#fde2e2"
    },
    {
        id:17,
        title:"ariel",
        icon:Ariel,
        flatIcon:ArielFlat,
        properties:[],
        supply:450,
        background:"#e0efef"
    },
    {
        id:18,
        title:"amirza",
        icon:Amirza,
        flatIcon:AmirzaFlat,
        properties:[],
        supply:550,
        background:"#f8e8eb"
    },
    {
        id:19,
        title:"topela",
        icon:Topela,
        flatIcon:TopelaFlat,
        properties:[],
        supply:800,
        background:"#fcfbee"
    },
    {
        id:20,
        title:"hawaiana",
        icon:Hawaiana,
        flatIcon:HawaianaFlat,
        properties:[],
        supply:1200,
        background:"#eef2ea"
    },
]
