import React, {FunctionComponent, useRef} from 'react';
import Text from "@components/common/text";
import styles from "./radio.module.scss";

interface CheckBoxProps {
    id?:string
    label?:string
    onChange:(id?:string, value?:any)=>void
    options?:{title:string, value:any}[]
    className?:string
}

const CheckBox:FunctionComponent<CheckBoxProps> = (props) => {

    const checked = useRef<any[]>([]);

    const handleChange = (value:any) => {
        const a = [...checked.current];
        const index = a.findIndex(c=>c===value);
        if(index !== -1){
            a.splice(index, 1);
        } else {
            a.push(value);
        }
        props.onChange(props.id, a);
        checked.current = a;
    }

    return (
        <div className={`${styles.container} ${props.className && props.className}`}>
            {
                props.label && <label className={styles.mainLabel}>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <div className={styles.items}>
                {
                    props.options && props.options.map((option, index)=>{
                        return <div key={index} className={"d-flex gap-2"}>
                            <input
                                name="gg"
                                type={"checkbox"}
                                onChange={()=>{handleChange(option.value)}}
                            />
                            <label>
                                <Text value={option.title} class={"capitalize"}/>
                            </label>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default CheckBox;