import {createContext} from "react";

interface LanguageContextProps {
    translate:(word:string)=>string
    dictionary:{[t:string]:string}
    onLanguageChange:(lang:string)=>void
}
export const LanguageContext = createContext<LanguageContextProps>({
    translate:()=>"",
    dictionary:{},
    onLanguageChange:()=>{}
});