import {getToken} from "@firebase/messaging";
import {messaging} from "../firebaseLoader";
import {failToast} from "@toast/index";

export const getFcmToken = async ():Promise<string|undefined> => {
    try {
        return await getToken(messaging,{vapidKey:"BG8ig4ID18wz3f0dxgCpciyNQtdCqHvi2mueIWVl1d_B1fc7Yf3PD1R5nSlZuPtzaxJ2tRAlpMpKruekFZkNnVc"});
    } catch (err){
        failToast("Notification not supported");
    }
}