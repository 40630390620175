import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./datePicker.module.scss";
import VerticalCarousel from "@components/basic/verticalCarousel";
import {buildArray} from "@utilities/array";
import moment from "moment";
import Text from "@components/common/text";

interface DatePickerProps {
    pickerType:"date"|"dateTime"|"time"
    onChange:(value:string|number)=>void
    outputType?:"Datetime"|"Timestamp"
    value?:string
    minValue?:string
    format?:string
}

const DatePicker:FunctionComponent<DatePickerProps> = (props) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [time, setTime] = useState<{min:number, hr:number, d:number, m:string, y:number}>({
        min:4,
        hr:4,
        d:5,
        m:"May",
        y:2023
    });

    const [years, setYears] = useState<number[]>([2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]);
    const [months, setMonths] = useState<string[]>([...monthNames].splice(0, 9));
    const [days, setDays] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9]);
    const [hours, setHours] = useState<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    const [minutes, setMinutes] = useState<number[]>([0, 1, 2, 3, 4, 5, 6, 7, 8]);

    useEffect(()=>{
        setInitial(props.value);
    }, [props.value])

    const setInitial = (timeString:string|undefined) => {
        let t: moment.Moment;
        if(!props.value) {
            t = moment()
        } else {
            t = moment(timeString)
        }
        setYears(getYears(t.year()-4));
        setMonths(getMonth(t.month()-4));
        setDays(getDays(Number(t.format('D'))-4));
        setHours(getHours(t.hour()-4));
        setMinutes(getMinutes(t.minute()-4));
    }

    useEffect(()=>{
        const monthIndex = monthNames.findIndex(m=>m === time.m)
        let timeString = `${time.y}-${String(monthIndex+1).padStart(2, "0")}-${String(time.d).padStart(2, "0")} ${String(time.hr).padStart(2, "0")}:${String(time.min).padStart(2, "0")}:00`;
        if(props.minValue){
            const diff=moment(timeString).diff(moment(props.minValue).format("YYYY-MM-DD HH:mm:ss"), "seconds");
            if(diff < 0) {
                setInitial(props.minValue);
                timeString = props.minValue;
            }
        }
        if(props.outputType === "Timestamp"){
            props.onChange(moment(timeString).unix());
        } else {
            props.onChange(moment(timeString).format(props.format || "YYYY-MM-DD HH:m:s"));
        }
    }, [time, props.minValue])

    const syncDays = (_month:string, _year:number) => {
        const maxDay = moment(`${_year}-${monthNames.findIndex(m=>m===_month)!+1}`, "YYYY-MM").daysInMonth();
        let targetDay = time.d;
        if(targetDay > maxDay) targetDay = maxDay
        const array = buildArray(1, maxDay, 9, targetDay-4);
        setDays(array);
        setTime(prevTime => ({...prevTime , d:array[4]}));
    }

    const getHours = (start:number):number[] => {
        const array = buildArray(0, 23, 9, start);
        setTime(prevTime => ({...prevTime , hr:array[4]}));
        return array;
    }

    const getMinutes = (start:number):number[] => {
        const array =  buildArray(0, 59, 9, start);
        setTime(prevTime => ({...prevTime , min:array[4]}));
        return array;
    }

    const getYears = (s:number):number[] => {
        const array =  Array.from({length: 9}, (x, i) => s + i);
        setTime(prevTime => ({...prevTime , y:array[4]}));
        return array;
    }

    const getMonth = (start:number):string[] => {
        const array =  buildArray(0, 11, 9, start);
        const monthArray = array.map(a=>monthNames[a]);
        setTime(prevTime => ({...prevTime , m:monthArray[4]}));
        return monthArray;
    }

    const getDays = (start:number):number[] => {
        const max = moment(`${time.y}-${monthNames.findIndex(m=>m===time.m)!+1}`, "YYYY-MM").daysInMonth();
        const array = buildArray(1, max, 9, start, true);
        setTime(prevTime => ({...prevTime , d:array[4]}));
        return array;
    }

    const hourChanges = (dir:-1|1) => {
        setHours(prevState => {
            let start = prevState[0] + dir;
            return getHours(start);
        })
    }

    const minuteChanges = (dir:-1|1) => {
        setMinutes(prevState => {
            let start = prevState[0] + dir;
            return getMinutes(start);
        })
    }

    const yearChanges = (dir:-1|1) => {
        setYears(prevState => {
            const array = getYears(prevState[0] + dir);
            syncDays(time.m, array[4])
            return array;
        });
    }

    const monthChanges = (dir:-1|1) => {
        setMonths(prevState => {
            let next = monthNames.findIndex(m=>m === prevState[0]) + dir;
            const array = getMonth(next);
            syncDays(array[4], time.y)
            return array;
        })
    }

    const dayChanges = (dir:-1|1) => {
        setDays(prevState => getDays(prevState[0] + dir))
    }

    return (
        <div className={"d-flex w-100 align-items-center gap-3"}>
            {
                ["date", "dateTime"].includes(props.pickerType) && <div
                    className={"d-flex flex-column"}
                    style={{flex:props.pickerType === "date" ? 1 : 0.6}}
                >
                    <p className={"mb-3"}><Text value={"set date"} class={"capitalize"}/></p>
                    <div className={styles.date}>
                        <hr className={styles.hr} style={{top:55}}/>
                        <hr className={styles.hr} style={{top:95}}/>
                        <VerticalCarousel
                            array={years}
                            elementHeight={40}
                            changeSlide={yearChanges}
                        />
                        <VerticalCarousel
                            array={months}
                            elementHeight={40}
                            changeSlide={monthChanges}
                        />
                        <VerticalCarousel
                            array={days}
                            elementHeight={40}
                            changeSlide={dayChanges}
                        />
                    </div>
                </div>
            }
            {
                ["time", "dateTime"].includes(props.pickerType) && <div
                    className={"d-flex flex-column"}
                    style={{flex:props.pickerType === "time" ? 1 : 0.4}}
                >
                    <div className={styles.time}>
                        <hr className={styles.hr} style={{top:55}}/>
                        <hr className={styles.hr} style={{top:95}}/>
                        <VerticalCarousel
                            array={hours}
                            elementHeight={40}
                            changeSlide={hourChanges}
                        />
                        <ul className={styles.timeDivider}>
                            <li>:</li>
                            <li>:</li>
                            <li>:</li>
                        </ul>
                        <VerticalCarousel
                            array={minutes}
                            elementHeight={40}
                            changeSlide={minuteChanges}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default DatePicker;