import React, {FunctionComponent, useState} from 'react';
import CustomButton from "@components/common/customButton";
import {Google, Loading, MetaMask, WalletConnect} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { onUserLoggedIn } from 'services/app/userServices';
import Modal from "@components/common/modal";
import Icon from "@components/common/icon";
import Alert from "@components/modals/alert";

interface AuthMethodsProps {
    gmail?:boolean
    metaMask?:boolean
    connectWallet?:boolean
}

const AuthMethods:FunctionComponent<AuthMethodsProps> = (props) => {

    const history = useHistory();

    const auth = getAuth();

    const [result, setResult] = useState<string>();
    const [onLoad, setOnLoad] = useState<boolean>(false);
    const [showResultModal, setShowResultModal] = useState<boolean>(false);

    const gmailLogin = async () => {
        try{
            setOnLoad(true);
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            await GoogleAuthProvider.credentialFromResult(result);
            const user = auth.currentUser;
            if(user){
                const convertedResult = user as {[key:string]:any}
                await onUserLoggedIn({
                    token: convertedResult.accessToken,
                    uid: convertedResult.uid,
                    email:convertedResult.email,
                    newUser:false
                });
            }
            setOnLoad(false);
        } catch (e:any) {
            setShowResultModal(true);
            setResult(e.message)
            setOnLoad(false);
        }
    }

    return (
        <div
            className={"d-flex align-items-center justify-content-center gap-3 bold"}
        >
            <div className={"d-flex gap-3"}>
                {
                    props.gmail && <CustomButton
                        theme={"no-border"}
                        icon={<Google/>}
                        className={"p-0"}
                        onClick={gmailLogin}
                    />
                }
                {
                    props.connectWallet && <CustomButton
                        theme={"no-border"}
                        icon={<WalletConnect/>}
                        className={"p-0"}
                        onClick={()=>history.push("/auth/login")}
                    />
                }
                {
                    props.metaMask && <CustomButton
                        theme={"no-border"}
                        icon={<MetaMask/>}
                        className={"p-0"}
                    />
                }
            </div>
            {
                onLoad && <Modal
                    onClose={()=>{}}
                    align={"center"}
                >
                    <div className={"p-4 d-flex flex-column gap-2 align-items-center"}>
                        <p>please wait ...</p>
                        <Icon size={20} color={"black"} icon={<Loading/>}/>
                    </div>
                </Modal>
            }
            {
                showResultModal && <Modal
                    align={"center"}
                    onClose={()=>{setShowResultModal(false)}}
                >
                    <Alert
                        title={result||""}
                        confirmText={"Ok"}
                        onConfirm={()=>setShowResultModal(false)}
                    />
                </Modal>
            }
        </div>
    );
};

export default AuthMethods;
