import React, {useState} from 'react';
import Html5QrcodePlugin from "@components/common/qrScanner";
import {assignSliceToUser} from "@webservices/slices";
import styles from "./scanQr.module.scss";
import Icon from "@components/common/icon";
import {Loading, ScanBarcode} from "@components/common/svgIcons";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import scanQr from "@assets/images/scanQr.png";
import CustomButton from "@components/common/customButton";
import BottomSheet from "@components/common/bottomSheet";
import confetti from "@assets/images/confetti.svg";
import {slices} from "../../constant/slices";
import Header from "@components/layouts/layoutElements/header";

const ScanQR = () => {

    const [showQRCode, setShowQRCode] = useState<boolean>(false);
    const [onLoad, setOnLoad] = useState<boolean>(false);
    const [sliceInfo, setSliceInfo] = useState<{title:string, icon:string, background:string}>();


    const history = useHistory();

    const onScanQR = async (value:string) => {
        setShowQRCode(false);
        setOnLoad(true);
        const slice = await assignSliceToUser(value);
        if(slice) {
            const wonSlice = slices.find(s=>s.id === slice.type);
            setSliceInfo({title:wonSlice!.title, icon:wonSlice!.icon, background:wonSlice!.background})
        }
        setOnLoad(false);
    }

    return (
        <div className={"w-100 h-100 d-flex flex-column"}>
            <Header
                title={"Scan QR Code"}
                menu
            />
            <div className={"d-flex w-100 flex-column justify-content-center align-items-center flex-grow-1"}>
                {
                    onLoad && <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                        <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                    </div>
                }
                {
                    (!onLoad && !sliceInfo) && <>
                        <img
                            src={scanQr}
                            alt=""
                            className={"w-50"}
                            style={{maxWidth:400}}
                        />
                        <div className={"my-4"} style={{textAlign: 'center', maxWidth:400}}>
                            <Text value={"Scan the QR code on your pizza box to collect a slice."} class={"capitalize"} />
                            <Text value={"Later, use your slices for a discount, as a gift, or save them up for even bigger rewards!"} class={"capitalize"} />
                        </div>
                        <CustomButton
                            className={styles.buttonSave}
                            theme={"primary-gradient"}
                            loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                            icon={<Icon size={24} color={"none"} icon={<ScanBarcode />} />}
                            text={<Text value={"Scan"} class={"capitalize"} />}
                            onClick={() => { setShowQRCode(true) }}
                        />
                    </>
                }
                {
                    sliceInfo && <div className={styles.success}>
                        <img
                            className={`w-50`}
                            style={{maxWidth:300}}
                            src={sliceInfo.icon}
                            alt={""}
                        />
                        <p className={"mb-2"}>Congratulation</p>
                        <p>You won a</p>
                        <h1><Text value={sliceInfo.title} class={"capitalize"} /></h1>
                        <img
                            className={styles.confetti}
                            src={confetti}
                            alt={""}
                        />
                        <CustomButton
                            className={`${styles.buttonSave} mt-3`}
                            theme={"primary-gradient"}
                            style={{zIndex:1}}
                            text={<Text value={"my slices"} class={"capitalize"} />}
                            onClick={() => { history.push("/")}}
                        />
                    </div>

                }
            </div>
            {
                showQRCode && <BottomSheet onClose={() => { setShowQRCode(false) }}>
                    <Html5QrcodePlugin
                        onScan={onScanQR}
                        onError={() => {}}
                    />
                </BottomSheet>
            }
        </div>
    );
};

export default ScanQR;
