import React, {FunctionComponent} from 'react';
import Text from "@components/common/text";
import styles from "./radio.module.scss";

interface RadioProps {
    id?:string
    label?:string
    default?:any
    onChange:(id?:string, value?:any)=>void
    options?:{title:any, value:any}[]
    className?:string
    align?:"row"|"column"
}

const Radio:FunctionComponent<RadioProps> = (props) => {

    return (
        <div className={`${styles.container} ${props.className && props.className}`}>
            {
                props.label && <label className={styles.mainLabel}>
                    <Text value={props.label} class={"capitalize"}/>
                </label>
            }
            <div className={`${styles.items} flex-${props.align ? props.align : "row"}`}>
                {
                    props.options && props.options.map((option, index)=>{
                        return <div
                            key={index} className={`d-flex gap-2`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                props.onChange(props.id, option.value)
                            }}
                        >
                            <input
                                name={props.id}
                                type={"radio"}
                                checked={option.value === props.default}
                            />
                            <label className={"flex-grow-1"}>
                                { option.title }
                            </label>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

    export default Radio;