import React, {FunctionComponent, useContext} from 'react';
import {LanguageContext} from "../../../contexts/languageContext";

interface FunctionComponentProps {
    value:string,
    variables?: string[]
    class?:"upperCase"|"lowerCase"|"capitalize"
}

const Text:FunctionComponent<FunctionComponentProps> = (props) => {

    const language = useContext(LanguageContext);

    const getText = ():string => {
        let text = language.translate(props.value);
        props.variables && props.variables.map((variable, index) => {
            text = text.replace(`%token_${index}%`, variable);
        })
        if(props.class){
            switch (props.class){
                case "capitalize":
                    text = text.charAt(0).toUpperCase() + text.slice(1);
                    break;

                case "lowerCase":
                    text = text.toLowerCase();
                    break;

                case "upperCase":
                    text = text.toUpperCase();
                    break;
            }
        }
        return text;
    }

    return (
        <>
            {getText()}
        </>
    );
};

export default Text;