import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./lockedPatternCard.module.scss";
import {claimPattern, Pattern} from "@webservices/patterns";
import {Slice} from "@types.d/Slice";
import Icon from "@components/common/icon";
import {Dollar2, Face, Like, Similarity} from "@components/common/svgIcons";
import {slices} from "../../../constant/slices";
import Divider from "@components/common/divider";
import CustomButton from "@components/common/customButton";
import Modal from "@components/common/modal";
import ConfirmModal from "@components/modals/confirmModal";
import {releaseSlices} from "@webservices/slices";
import {failToast} from "@toast/index";
import SlicePreview from "@components/modals/slicePreview";
import SquareMergedSlices from "@components/app/mergedSlices/square";
import Confetti from "@assets/images/confetti.svg";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import rewardsImage from "@assets/images/rewards.svg";

interface LockedPatternCardProps {
    pattern:Pattern
    userSlices:{[K:string]:number}
    userSlicesRaw:Slice[]
}

const LockedPatternCard:FunctionComponent<LockedPatternCardProps> = (props) => {

    const history = useHistory();

    const [missedSlices, setMissedSlices] = useState<{[K:string]:number}>({});
    const [matchedSlices, setMatchSlices] = useState<(number|undefined)[]>([]);
    const [similarity, setSimilarity] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [claimLoading, setClaimLoading] = useState<boolean>(false);
    const [enlargePattern, setEnlargePattern] = useState<boolean>(false);
    const [selectedSlice, setSelectedSlice] = useState<number>();

    useEffect(()=>{
        let missed:{[K:string]:number} = {};
        const matched:(number|undefined)[] = [];
        const userSlices = {...props.userSlices};
        let matchedCount = 0;
        props.pattern.slices.forEach(slice => {
            if(!userSlices[slice.type]) {
                missed = {...missed, [slice.type]:slice.amount};
                for(let i=0; i<slice.amount; i++){
                    matched.push(undefined);
                }
            } else {
                let existSlices:number;
                const diff = slice.amount - userSlices[slice.type];
                if(diff > 0){
                    missed = {...missed, [slice.type]:diff};
                    for(let i=0; i<diff; i++){
                        matched.push(undefined);
                    }
                    existSlices = userSlices[slice.type];
                } else {
                    existSlices = slice.amount;
                }
                for(let i=0; i<existSlices; i++){
                    matchedCount+=1;
                    matched.push(slice.type);
                }
            }
        });
        setMissedSlices(missed);
        setMatchSlices(matched);
        setSimilarity(matchedCount*100/8)
    }, [])

    const requestForClaim = async () => {
        setClaimLoading(true);
        const missed = props.pattern.slices.find(s =>  !props.userSlices[s.type] || s.amount > props.userSlices[s.type]);
        if(missed){
            failToast("You dont have sufficient slices to claim");
            setClaimLoading(false);
            setShowConfirmModal(false);
            return;
        }
        try {
            await releaseSlices(getSlicesToRelease());
            await claimPattern(props.pattern.id);
            setShowSuccessModal(true);
            setClaimLoading(false);
            setShowConfirmModal(false);
        } catch (e) {
            failToast("Unexpected error!! Please try a few minutes later.");
            setClaimLoading(false);
            setShowConfirmModal(false);
            return;
        }
    }

    const getSlicesToRelease = () => {
        const slicesToRelease:Slice[] = [];
        const userSlices = [...props.userSlicesRaw];
        props.pattern.slices.forEach(slice => {
            for(let i=0; i<slice.amount; i++){
                const index = userSlices.findIndex(us=>us.type === slice.type);
                slicesToRelease.push(userSlices[index]);
                userSlices.splice(index, 1);
            }

        });
        return slicesToRelease;
    }

    return (
        <>
            <div className={"d-flex w-100 align-items-center flex-column gap-2"}>
                <div className={styles.pattern} onClick={() => {setEnlargePattern(true)}}>
                    <SquareMergedSlices
                        slices={matchedSlices}
                        borderRadius={1}
                    />
                </div>
                <div className={styles.statics}>
                    <div className={`${styles.staticsItem}`}>
                        <span className={"d-flex gap-2 align-items-center"}>
                            <Icon size={20} color={"black"} icon={<Similarity/>}/>
                            <p className={" f-s-2"}>Similarity</p>
                        </span>
                        <p className={"bold"}>{similarity}%</p>
                    </div>
                    <div className={styles.staticsItem}>
                        <span className={"d-flex gap-2 align-items-center"}>
                            <Icon size={20} color={"black"} icon={<Dollar2/>}/>
                            <p className={" f-s-2"}>Revenue Rate</p>
                        </span>
                        <p className={"bold"}>{props.pattern.revenueRate}</p>
                    </div>
                </div>
            </div>
            <h1 className={"mt-3"}>Reward: {props.pattern.reward}</h1>
            {
                Object.keys(missedSlices).length > 0 && <>
                    <p className={"my-3"}>You need these slice to complete this pattern. Tap yo buy.</p>
                    <div className={"d-flex w-100 justify-content-center gap-1"}>
                        {
                            Object.keys(missedSlices).map((slice, index) => {
                                return <div
                                    key={index}
                                    className={styles.missed}
                                    onClick={()=>setSelectedSlice(Number(slice))}
                                >
                                    <img
                                        src={slices[Number(slice)-1].icon}
                                        style={{background : slices[Number(slice)-1].background, borderRadius:8}}
                                        alt={slices[Number(slice)-1].title}
                                    />
                                    <p>x{missedSlices[slice]}</p>
                                </div>
                            })
                        }
                    </div>
                </>
            }
            <Divider/>
            <div className={"d-flex w-100 gap-2 justify-content-center"}>
                <CustomButton
                    theme={"primary-gradient"}
                    text={"claim"}
                    className={"flex-grow-1"}
                    style={missedSlices[0] ? {opacity:0.5} : {}}
                    onClick={missedSlices[0] ? undefined : ()=>{setShowConfirmModal(true)}}
                />
            </div>
            {
                selectedSlice && <Modal
                    onClose={()=>setSelectedSlice(undefined)}
                    align={"center"}
                    closeOnOutSideClick
                >
                    <SlicePreview
                        sliceId={selectedSlice}
                        onClose={()=>setSelectedSlice(undefined)}
                    />
                </Modal>
            }
            {
                showConfirmModal && <Modal
                    align={"center"}
                    onClose={()=>{setShowConfirmModal(false)}}
                >
                    <ConfirmModal
                        title={"Are you sure to claim your slice ?"}
                        confirmText={"yes, claim"}
                        onConfirm={requestForClaim}
                        onCancel={()=>{setShowConfirmModal(false)}}
                        inProgress={claimLoading}
                    />
                </Modal>
            }
            {
                enlargePattern && <Modal
                    align={"center"}
                    closeOnOutSideClick={true}
                    onClose={() => { setEnlargePattern(false) }}
                >
                    <div className={styles.enlargedPatternContainer}>
                        <div className={styles.enlargedPattern}>
                            <SquareMergedSlices
                                slices={matchedSlices}
                            />
                        </div>
                    </div>
                </Modal>
            }
            {
                showSuccessModal && <Modal
                    align={"center"}
                    onClose={()=> {
                        setShowSuccessModal(false);
                        history.push('/my-patterns');
                    }}
                    backgroundImage={Confetti}
                >
                    <div className={"w-100 d-flex flex-column justify-content-center align-items-center p-4 gap-2"}>
                        <img src={rewardsImage} alt={"rewards"} style={{maxWidth:300}}/>
                        <p className={"text-center"}><Text value={"Great! You won Rewards as below."} class={"capitalize"} /></p>
                        <h1 className={"text-center"}><Text value={props.pattern.reward} class={"capitalize"} /></h1>
                        <CustomButton
                            className={styles.buttonSave}
                            theme={"primary-gradient"}
                            text={<Text value={"Ok"} class={"capitalize"} />}
                            onClick={() => {
                                history.push('/my-patterns');
                            }}
                        />
                    </div>
                </Modal>
            }
        </>
    );
};

export default LockedPatternCard;
