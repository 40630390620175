import React, {FunctionComponent, useContext, useEffect, useRef, useState} from 'react';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {Marker} from "@types.d/Marker";

interface MarkerProps {
    markers: Marker[]
    markersBreakPoints?: {
        zoomMin: number
        zoomMax: number
    }[]
    zoomExtents?:boolean
    clearOnRefresh?:boolean
    clearUnwanted?:boolean
    reset?:{
        status:boolean
        onReset:()=>void
    }
}

const MapMarker:FunctionComponent<MarkerProps> = (props) => {

    const { map, googleObject, zoom } = useContext(MapContext);
    const markers = useRef<{
        id:number|string,
        marker:google.maps.Marker
    }[]>([]);
    const advancedMarkers = useRef<{
        id:number|string,
        marker:google.maps.marker.AdvancedMarkerElement
    }[]>([]);
    const [breakPoint, setBreakPoint] = useState<number>(0);

    useEffect(() => {
        if (props.markersBreakPoints) {
            const zoomIndex = props.markersBreakPoints.findIndex(bp => Number(zoom) >= bp.zoomMin && Number(zoom) <= bp.zoomMax)
            if (zoomIndex !== -1) setBreakPoint(zoomIndex);
        }
    }, [zoom])

    useEffect(()=> {
        if(props.reset?.status) {
            clearAll();
            props.reset.onReset();
            addMarker();
            return;
        }
        props.clearOnRefresh && clearAll();
        props.clearUnwanted && clearUnwanted();
        addMarker();
    }, [map, props.markers])

    useEffect(()=> {
        clearAll();
        addMarker();
    }, [breakPoint])

    const clearUnwanted = () => {
        if(!props.markers[0]){
            clearAll();
            return;
        }
        const newIds = props.markers.map(m=>m.id);
        advancedMarkers.current.map((marker, index)=>{
            if(!newIds.includes(marker.id)){
                marker.marker.map = null;
                advancedMarkers.current.splice(index, 1);
            }
        });
    }

    const clearAll = () => {
        advancedMarkers.current.map(p=>p.marker.map = null);
        markers.current.map(p=>p.marker.setMap(null));
        advancedMarkers.current = [];
        markers.current = [];
    }

    const addMarker = () => {
        if (!map || !props.markers || !googleObject) return;
        const bounds = new googleObject.maps.LatLngBounds();
        props.markers.map(marker => {
            const exist = markers.current.find(m=>m.id === marker.id);
            const adExist = advancedMarkers.current.find(m=>m.id === marker.id);
            if(props.zoomExtents) {
                bounds.extend( new googleObject.maps.LatLng(marker.position));
            }
            if(exist || adExist){
                if(marker.advanceMarker && adExist?.marker) adExist.marker.content = marker.advanceMarker[breakPoint](marker.info);
            } else {
                let createdMarker:google.maps.marker.AdvancedMarkerElement|google.maps.Marker;
                if(marker.advanceMarker) {
                    createdMarker = new googleObject.maps.marker.AdvancedMarkerElement({
                        map,
                        position: marker.position,
                        content: marker.advanceMarker[breakPoint](marker.info),
                    });
                    advancedMarkers.current.push({id:marker.id, marker:createdMarker});
                } else {
                    createdMarker = new googleObject.maps.Marker({
                        map,
                        position: marker.position,
                        icon:marker.icon
                    });
                    markers.current.push({id:marker.id, marker:createdMarker});
                }
                marker.onClick && createdMarker.addListener("click", () => marker.onClick!(marker.id))
            }
        })
        if(props.zoomExtents) {
            if(!bounds.isEmpty()) {
                map.fitBounds(bounds)
            }
        }
    }

    return (<></>);
};

export default MapMarker;
