import {createContext} from "react";

interface GlobalContextProps {
    unreadNotifications:number
    updateNotifications:(numberToAdd:number)=>void
}

export const GlobalContext = createContext<GlobalContextProps>({
    unreadNotifications:0,
    updateNotifications:(numberToAdd:number)=>{},
});
