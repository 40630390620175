import locIcon from "@assets/icons/location.svg";
import {Coordinate} from "@types.d/Coordinate";
import { DEFAULT_MAP_CENTER } from "config";

export const getCurrentLocation = () : Promise<Coordinate|undefined> => {
    return new Promise<Coordinate|undefined>((resolve, reject)=> {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        }, (err)=>{
            switch (err.code){
                case 1:
                    navigator.permissions.query({ name: "geolocation" })
                        .then((result) => {
                            if(result.state === 'denied'){
                                reject(1)
                            }
                        })
                    break;

                case 3:
                    resolve(DEFAULT_MAP_CENTER);
                    break

                default:
                    reject(err);
            }
        }, {timeout:5000})
    })
}

export const goToCurrentLocation = (map:google.maps.Map, googleObject:typeof google) => {
    getCurrentLocation().then(pos => {
        if(pos) {
            new googleObject.maps.Marker({
                map: map,
                position: {
                    lat: pos.lat,
                    lng: pos.lng
                },
                icon:{
                    url: locIcon,
                    scaledSize: new google.maps.Size(30, 30)
                }
            });
            map.setCenter(pos)
        }
    })
}

export const getDistanceMatrix = async (
    origin:google.maps.LatLng,
    destination:google.maps.LatLng,
    mode:google.maps.TravelMode
):Promise<google.maps.DistanceMatrixResponseElement> => {
    const service = new google.maps.DistanceMatrixService();
    const res =  await  service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: mode
    });
    return res.rows[0].elements[0];
}

export const getLocationPermissionStatus = async ():Promise<PermissionStatus> => {
    return await navigator.permissions.query({ name: "geolocation" })
}