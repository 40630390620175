import React, {useEffect} from 'react';
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";
import {getAuth, isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";
import {failToast} from "@toast/index";
import Text from "@components/common/text";
import {handleFirebaseError} from "../../../services/firebaseErrorHandler";
import {useHistory} from "react-router-dom";
import {onUserLoggedIn} from "../../../services/app/userServices";

const VerifyEmail = () => {

    const history = useHistory();
    const query = new URLSearchParams(history.location.search);

    useEffect(()=>{
        (async () => {
            try{
                const auth = getAuth();
                if (isSignInWithEmailLink(auth, window.location.href)) {
                    const email = window.localStorage.getItem('loggedInEmail');
                    if (!email) {
                        failToast(<Text value={"please provide your email for confirmation"} class={"capitalize"}/>);
                        history.push("/");
                        return;
                    }
                    const userCredential = await signInWithEmailLink(auth, email, window.location.href);
                    const convertedResult = userCredential as {[key:string]:any};
                    await onUserLoggedIn({
                        token: convertedResult._tokenResponse.idToken,
                        newUser:convertedResult._tokenResponse.isNewUser,
                        refreshToken: convertedResult._tokenResponse.refreshToken,
                        uid: convertedResult.user.uid,
                        email: email,
                        ref:query.get("ref")
                    });
                    localStorage.removeItem('emailForSignIn');
                }
            } catch (error) {
                handleFirebaseError(error);
            }
        })()
    }, [])

    return (
        <div className={"d-flex flex-column p-4 justify-content-center align-items-center h-100"}>
            <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
            <p className={"w-75 text-center mt-3"}>please be patient to complete the login process</p>
        </div>
    );
};

export default VerifyEmail;