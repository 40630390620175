import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import Icon from "@components/common/icon";
import {Warning} from "@components/common/svgIcons";
interface ConfirmModalProps {
    title:string
    confirmText?:string
    onConfirm:()=>void
}

const Alert:FunctionComponent<ConfirmModalProps> = (props) => {
    return (
        <div className={"d-flex justify-content-center align-items-center flex-column gap-3 p-4"}>
            <Icon size={30} color={"none"} icon={<Warning/>} gradient/>
            <p className={"text-center mb-3"}>{<Text value={props.title}/>}</p>
            <CustomButton
                text={<Text value={props.confirmText ? props.confirmText : "ok"} class={"capitalize"}/>}
                theme={"primary"}
                onClick={props.onConfirm}
            />
        </div>
    );
};

export default Alert;
