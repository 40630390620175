if (process.env.REACT_APP_ENV === "local") {
    module.exports.HOME_URL = "http://localhost:4500/";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
    module.exports.DEFAULT_MAP_CENTER = {lat:39.73787781099305, lng:-104.98921126542218};
}
if(process.env.REACT_APP_ENV === "main") {
    module.exports.HOME_URL = "https://app.pzza.io/";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
    module.exports.DEFAULT_MAP_CENTER = {lat:39.73787781099305, lng:-104.98921126542218};
}
