import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import HorizontalSlider from "@components/basic/horizontalSlider";
import {slices} from "../../../constant/slices";
import Icon from "@components/common/icon";
import {Loading, Lock} from "@components/common/svgIcons";
import {getUserSlices} from "@webservices/slices";
import {Slice} from "@types.d/Slice";

interface UserSlicesProps {
    onSelect:(id:number, status:"lock"|"unlock")=>void
    decreaseOnSelect?:boolean
    release?:{id:number, amount:number, actionId:string}
    disabled?: boolean
    size?:"large"|"small"
    hideLock?:boolean
}

const UserSlices:FunctionComponent<UserSlicesProps> = (props) => {

    const [userSlices, setUserSlices] = useState<{[K:string]:number}>({});
    const [onLoad, setOnLoad] = useState<boolean>(true);

    useEffect(()=>{
        (async ()=>{
            setOnLoad(true);
            const result = await getUserSlices();
            const groupedResult = groupSlices(result);
            setUserSlices(groupedResult);
            setOnLoad(false);
        })()
    }, [])

    useEffect(()=>{
        if(!props.release?.id) return;
        setUserSlices(prevState => {
            const temp = {...prevState};
            temp[props.release!.id] = temp[props.release!.id]+props.release!.amount;
            return temp;
        })
    }, [props.release])

    const groupSlices = (slices:Slice[]):{[K:string]:number} => {
        let sliceTypeArray:{[K:string]:number} = {};
        slices.map(item => {
            if(!sliceTypeArray[item.type]){
                sliceTypeArray = {...sliceTypeArray, [item.type]:1};
            } else {
                sliceTypeArray[item.type]++;
            }
        })
        return sliceTypeArray;
    }

    const handleSelect = (id: number, status: "lock" | "unlock") => {
        if (!props.disabled) {
            props.onSelect(id, status);
            if (props.decreaseOnSelect && status === "unlock") {
                setUserSlices(prevState => {
                    const temp = {...userSlices};
                    temp[id] = temp[id] - 1;
                    return temp;
                })
            }
        }
    }

    const SliceRow = (props:{i:number, amount:number, width:number, hideLock:boolean}) => {
        let sortedSlices = [...slices];
        return <div
            className={"position-relative d-flex flex-column align-items-center mb-2"}
            style={{width:`${props.width}%`}}
            onClick={()=>handleSelect(sortedSlices[props.i].id, (props.amount && props.amount>0) ? "unlock" : "lock")}
        >
            <img
                className={`w-75 mb-1 ${(!props.amount || props.amount <= 0) && "opacity-50"}`}
                style={{maxWidth:100, borderRadius:10}}
                src={sortedSlices[props.i].icon}
                alt={sortedSlices[props.i].title}
            />
            <p>x{props.amount || 0}</p>
            {
                (!props.hideLock && (!props.amount || props.amount <= 0)) && <div className={"position-absolute"}>
                    <Icon size={40} color={"none"} icon={<Lock/>} />
                </div>
            }
        </div>
    }

    const slicesElements = useMemo(()=>{
        let sortedSlices = [...slices];
        let array = [];
        if(props.size === "large"){
            for(let i=0; i<sortedSlices.length; i++) {
                array.push(<SliceRow i={i} amount={userSlices[sortedSlices[i].id] || 0} width={20} hideLock={!!props.hideLock}/>)
            }
            return array;
        } else {
            for(let i=0; i<sortedSlices.length; i+=2) {
                array.push(
                    <div>
                        <SliceRow i={i} amount={userSlices[sortedSlices[i].id] || 0} width={100} hideLock={!!props.hideLock}/>
                        <SliceRow i={i+1} amount={userSlices[sortedSlices[i+1].id] || 0} width={100} hideLock={!!props.hideLock}/>
                    </div>
                )
            }
            return <HorizontalSlider slideWidth={60} slides={array}/>;
        }

    }, [userSlices])

    return (
        <>
            {
                onLoad ? <div
                    className={"w-100 d-flex justify-content-center align-items-center"}
                    style={{height:props.size === "large" ? 337 : 140}}
                >
                    <Icon size={20} color={"none"} icon={<Loading/>} gradient/>
                </div> : <div className={"d-flex w-100 justify-content-between flex-wrap"} style={{flexBasis:"20%"}}>
                    {slicesElements}
                </div>
            }
        </>
    );
};

export default UserSlices;
