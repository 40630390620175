import React, {FunctionComponent, useContext, useState} from 'react';
import {MapContext} from "@components/common/map/googleMap/provider/mapContext";
import {Gps} from "@components/common/svgIcons";
import Icon from "@components/common/icon";
import styles from "./controls.module.scss";
import {getLocationPermissionStatus, goToCurrentLocation} from "@components/common/map/googleMap/services";
import BottomSheet from "@components/common/bottomSheet";
import LocationService from "@components/bottomSheets/locationService";
import UnblockLocationService from "@components/bottomSheets/locationService/unblockLocationService";

interface CurrentLocationProps {
    type?:"control"|"button"
}

const CurrentLocation:FunctionComponent<CurrentLocationProps> = (props) => {

    const { map, googleObject } = useContext(MapContext);
    const [showPermissionBT, setShowPermissionBT] = useState<boolean>(false);
    const [showUnblockPermissionBT, setShowUnblockPermissionBT] = useState<boolean>(false);


    const handleClick = async (e:any) => {
        e.stopPropagation();
        const status = await getLocationPermissionStatus();
        if(status.state === "granted"){
            recenter();
            return;
        }
        if(status.state === "prompt"){
            setShowPermissionBT(true);
        } else if(status.state === "denied") {
            setShowUnblockPermissionBT(true);
        }
    }

    const recenter = () => {
        if(!map || !googleObject) return;
        goToCurrentLocation(map, googleObject);
        setShowPermissionBT(false);
    }

    return (
        <>
            <button
                onClick={handleClick}
                className={props.type === "control" ? styles.control : styles.button}
            >
                <Icon size={24} color={'primary'} icon={<Gps/>} />
            </button>
            {
                showPermissionBT && <BottomSheet onClose={()=>setShowPermissionBT(false)}>
                    <LocationService
                        onConfirm={recenter}
                        onCancel={()=>setShowPermissionBT(false)}
                    />
                </BottomSheet>
            }
            {
                showUnblockPermissionBT && <BottomSheet onClose={()=>setShowUnblockPermissionBT(false)}>
                    <UnblockLocationService
                        onConfirm={()=>setShowUnblockPermissionBT(false)}
                    />
                </BottomSheet>
            }
        </>
    );
};

export default CurrentLocation;