import React, {FunctionComponent, ReactElement, useState} from 'react';
import styles from "./hss.module.scss";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";

interface HorizontalSlideShowProps {
    slides:ReactElement[]
}

const HorizontalSlideShow:FunctionComponent<HorizontalSlideShowProps> = (props) => {

    const [selected, setSelected] = useState<number>(0);
    const [onChange, setOnChange] = useState<boolean>(false);
    const [start, setStart] = useState<number>();

    const changeSlide = (dir:-1|1) => {
        if(selected+dir < 0 || selected+dir > props.slides.length-1) return;
        setOnChange(true);
        setTimeout(()=>{
            setSelected(prevState => prevState + dir);
            setOnChange(false);
        }, 300)
    }

    const handleMouseDown = (e:React.MouseEvent<HTMLDivElement>) => {
        setStart(e.clientX);
    }

    const handleMouseTop = (e:React.MouseEvent<HTMLDivElement>) => {
        setStart(undefined);
    }

    const handleTouchStart = (e:React.TouchEvent<HTMLDivElement>) => {
        setStart(e.touches[0].clientX);
    }

    const handleTouchMove = (e:React.TouchEvent<HTMLDivElement>) => {
        if(!start) return;
        if(start - e.touches[0].clientX > 100) {
            setStart(undefined);
            changeSlide(1)
            return;
        }
        if(start - e.touches[0].clientX < -100) {
            setStart(undefined);
            changeSlide(-1)
            return;
        }
    }

    const handleMouseMove = (e:React.MouseEvent<HTMLDivElement>) => {
        if(!start) return;
        if(start - e.clientX > 100) {
            setStart(undefined);
            changeSlide(1)
            return;
        }
        if(start - e.clientX < -100) {
            setStart(undefined);
            changeSlide(-1)
            return;
        }
    }

    return (
        <div
            className={"h-100 w-100"}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseTop}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onMouseMove={handleMouseMove}
        >
            <div className={`${styles.slideContainer} ${onChange && styles.onHide} h-100`}>
                {
                    props.slides[selected]
                }
            </div>
            {
                selected !== props.slides.length-1 && <div className={styles.controls}>
                    <div>
                        <CustomButton
                            theme={"no-border"}
                            text={<Text value={"skip"}/>}
                            className={"p-0"}
                            onClick={()=>setSelected(props.slides.length-1)}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default HorizontalSlideShow;