import React, {FunctionComponent} from 'react';
import styles from "./mergedSlices.module.scss";
import {slices} from "../../../constant/slices";
import MissedSlice from "@assets/icons/slices/squareMissedSlice.svg";


interface SquareMergedSlicesProps {
    slices:(number|undefined)[]
    onSliceClick?:(id:number)=>void
    borderRadius?:number
}

const SquareMergedSlices:FunctionComponent<SquareMergedSlicesProps> = (props) => {

    return (
        <div className={styles.squarePattern}>
            {
                props.slices.map((slice, index)=>{
                    return <img
                        key={index}
                        src={!slice ? MissedSlice :slices[slice-1].flatIcon}
                        alt={!slice ? "" : slices[slice-1].title}
                        onClick={()=>{(slice && props.onSliceClick) && props.onSliceClick(index)}}
                    />
                })
            }
        </div>
    );
};

export default SquareMergedSlices;
